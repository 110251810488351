import React, { useState, useMemo, useContext, useEffect } from "react";
import { DataTable, HeaderCell } from "../../components/Table";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Badge } from "../../shadcn/components/badge";
import { Switch } from "../../shadcn/components/switch";
import { cn } from "../../shadcn/lib/utils";
import { processRelevantQuote, DocView } from "./DocView";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { SearchResult } from "../../types";

export const itemSearchKey = (item: Record<string, any>) => {
  const key = ((item["section"] ?? "") + " " + (item["language"] ?? ""))
    .trim()
    .toLowerCase();
  return key;
};

export const getRelatedReferenceData = (
  relatedData: Record<string, any>[],
  referenceType: string
) => {
  const relatedReferenceData = relatedData.filter((item: any) => {
    switch (referenceType) {
      case "NCQA HPA 2024":
        return item["source"] === "NCQA HPA 2024";
      case "KKA":
        return item["source"] === "H&S Code" || item["source"] === "28 CCR";
      default:
        return (
          item["source"] !== "NCQA HPA 2024" &&
          item["source"] !== "H&S Code" &&
          item["source"] !== "28 CCR"
        );
    }
  });
  return relatedReferenceData;
};

const ReferenceDisplay = (props: {
  relatedItems: Record<string, any>[];
  searchText: string;
  color: string;
  setActiveSearchDocId: (searchDocId: string | null) => void;
  activeSearchDocId: string | null;
}) => {
  return (
    <div className="flex gap-1 flex-wrap">
      {props.relatedItems.map((item) => (
        <Tooltip key={item["section"]}>
          <TooltipTrigger>
            <Badge
              variant="default"
              className={cn(
                "text-xs whitespace-pre inline-block cursor-pointer",
                {
                  "bg-blue-300 hover:bg-blue-600":
                    props.color === "blue" &&
                    item["id"] !== props.activeSearchDocId,
                  "bg-blue-600":
                    props.color === "blue" &&
                    item["id"] === props.activeSearchDocId,
                  "bg-red-300 hover:bg-red-600":
                    props.color === "red" &&
                    item["id"] !== props.activeSearchDocId,
                  "bg-red-600":
                    props.color === "red" &&
                    item["id"] === props.activeSearchDocId,
                  "bg-orange-300 hover:bg-orange-600":
                    props.color === "orange" &&
                    item["id"] !== props.activeSearchDocId,
                  "bg-orange-600":
                    props.color === "orange" &&
                    item["id"] === props.activeSearchDocId,
                  "bg-green-300 hover:bg-green-600":
                    props.color === "green" &&
                    item["id"] !== props.activeSearchDocId,
                  "bg-green-600":
                    props.color === "green" &&
                    item["id"] === props.activeSearchDocId,
                },
                props.searchText &&
                  itemSearchKey(item).includes(
                    props.searchText.toLowerCase()
                  ) &&
                  "bg-yellow-400 text-black"
              )}
              onClick={() => props.setActiveSearchDocId(item["id"])}
            >
              {item["section"]}
            </Badge>
          </TooltipTrigger>
          <TooltipContent className="max-w-[400px] max-h-[400px] overflow-y-auto">
            {item["language"] ?? ""}
          </TooltipContent>
        </Tooltip>
      ))}
    </div>
  );
};

const FullTextDisplay = (props: {
  relatedItems: Record<string, any>[];
  searchText: string;
  color: string;
  setActiveSearchDocId: (searchDocId: string | null) => void;
  activeSearchDocId: string | null;
}) => {
  const highlightText = (
    text: string,
    searchText: string,
    keywords?: string[]
  ) => {
    if (!text) return "";

    // First handle keyword bolding
    let result = text;
    if (keywords?.length) {
      result = keywords.reduce((acc, keyword) => {
        const regex = new RegExp(
          `(${keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
          "gi"
        );
        return acc.replace(regex, "<strong>$1</strong>");
      }, result);
    }

    // Then handle search text highlighting
    if (searchText) {
      result = result.replace(
        new RegExp(
          `(${searchText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
          "gi"
        ),
        '<span class="bg-yellow-200">$1</span>'
      );
    }

    return <span dangerouslySetInnerHTML={{ __html: result }} />;
  };

  return (
    <div className="space-y-2">
      {props.relatedItems.map((record, index) => {
        return (
          <div key={index} className="space-y-4">
            <div className="font-semibold">
              <Badge
                variant="default"
                className={cn(
                  "text-xs whitespace-pre cursor-pointer",
                  {
                    "bg-blue-600 hover:bg-blue-800":
                      props.color === "blue" &&
                      record["id"] !== props.activeSearchDocId,
                    "bg-blue-800":
                      props.color === "blue" &&
                      record["id"] === props.activeSearchDocId,
                    "bg-red-600 hover:bg-red-800":
                      props.color === "red" &&
                      record["id"] !== props.activeSearchDocId,
                    "bg-red-800":
                      props.color === "red" &&
                      record["id"] === props.activeSearchDocId,
                    "bg-orange-600 hover:bg-orange-800":
                      props.color === "orange" &&
                      record["id"] !== props.activeSearchDocId,
                    "bg-orange-800":
                      props.color === "orange" &&
                      record["id"] === props.activeSearchDocId,
                    "bg-green-600 hover:bg-green-800":
                      props.color === "green" &&
                      record["id"] !== props.activeSearchDocId,
                    "bg-green-800":
                      props.color === "green" &&
                      record["id"] === props.activeSearchDocId,
                  },
                  props.searchText &&
                    (record.section ?? "")
                      .toLowerCase()
                      .includes(props.searchText.toLowerCase()) &&
                    "bg-yellow-400 text-black"
                )}
                onClick={() => props.setActiveSearchDocId(record["id"])}
              >
                {record.section ?? ""}
              </Badge>
            </div>
            {record.section_title && (
              <div className="text-gray-500 font-semibold">
                {record.section_title}
              </div>
            )}
            {record.subsection_title && (
              <div className="italic">{record.subsection_title}</div>
            )}
            <div className="text-gray-500 whitespace-pre-wrap text-xs">
              {highlightText(
                record.language ?? "",
                props.searchText,
                record.keywords // Assuming keywords are available in the record
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SourceDisplay = (props: {
  showText: boolean;
  relatedItems: Record<string, any>[];
  searchText: string;
  color: string;
  setActiveSearchDocId: (searchDocId: string | null) => void;
  activeSearchDocId: string | null;
}) => {
  return props.showText ? (
    <FullTextDisplay
      relatedItems={props.relatedItems}
      searchText={props.searchText}
      color={props.color}
      setActiveSearchDocId={props.setActiveSearchDocId}
      activeSearchDocId={props.activeSearchDocId}
    />
  ) : (
    <ReferenceDisplay
      relatedItems={props.relatedItems}
      searchText={props.searchText}
      color={props.color}
      setActiveSearchDocId={props.setActiveSearchDocId}
      activeSearchDocId={props.activeSearchDocId}
    />
  );
};

export const CrosswalkTable = (props: {
  data: Record<string, any>[];
  references: SearchResult[];
  children: React.ReactNode;
  setActiveReference: React.Dispatch<
    React.SetStateAction<{
      id: string;
    } | null>
  >;
  activeReference: {
    id: string;
  } | null;
  searchText: string;
}) => {
  const [activeSearchDocId, setActiveSearchDocId] = useState<string | null>(
    null
  );

  // Sort the data by index
  const sortedData = useMemo(() => {
    return [...props.data].sort((a, b) => a.index - b.index);
  }, [props.data]);

  const activeSearchResult = useMemo(
    () =>
      props.references.find(
        (result) => result.search_doc_id === activeSearchDocId
      ),
    [activeSearchDocId]
  );

  const { setPageNumber, setCitations } = useContext(DocViewerContext);

  useEffect(() => {
    if (activeSearchResult) {
      setPageNumber(activeSearchResult.page ?? 1);
      if (activeSearchResult.relevant_quote) {
        const { quote } = processRelevantQuote(
          activeSearchResult.text,
          activeSearchResult.relevant_quote.start_index,
          activeSearchResult.relevant_quote.end_index
        );
        setCitations([
          {
            id: "quoteCitation",
            match: quote,
            exactMatch: false,
            page: activeSearchResult.page ?? 1,
          },
        ]);
      }
    } else {
      setPageNumber(1);
      setCitations([]);
    }
  }, [activeSearchResult?.search_doc_id]);

  let columns: any[] = [
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Show Text" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["id"];
        return (
          <Switch
            id="full-text-toggle"
            checked={props.activeReference?.id === rowId}
            onCheckedChange={(checked) =>
              props.setActiveReference(checked ? { id: rowId } : null)
            }
          />
        );
      },
      id: "show-text",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Category" />;
      },
      cell: ({ row }: any) => {
        return <span>{row.original["category"]}</span>;
      },
      id: "category",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Topic" />;
      },
      cell: ({ row }: any) => {
        const text = row.original["topic"];
        let result = text;

        // Handle search text highlighting
        if (props.searchText) {
          const searchRegex = new RegExp(`(${props.searchText})`, "gi");
          result = result.replace(
            searchRegex,
            '<span class="bg-yellow-200">$1</span>'
          );
        }

        return <div dangerouslySetInnerHTML={{ __html: result }} />;
      },
      id: "topic",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="Summary" />;
      },
      cell: ({ row }: any) => {
        const text = row.original["summary"];
        const keywords = row.original["summary_keywords"].map(
          (keyword: string) => keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
        );
        let result = text;

        // First handle search text highlighting
        if (props.searchText) {
          const searchRegex = new RegExp(`(${props.searchText})`, "gi");
          result = result.replace(
            searchRegex,
            '<span class="bg-yellow-200">$1</span>'
          );
        }

        // Then handle keyword bolding
        keywords.forEach((keyword: string) => {
          const regex = new RegExp(`(${keyword})`, "gi");
          result = result.replace(regex, "<strong>$1</strong>");
        });

        return (
          <div
            className="w-[200px]"
            dangerouslySetInnerHTML={{ __html: result }}
          />
        );
      },
      id: "summary",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="DHCS" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["id"];
        const relatedData = [row.original["dhcs_contract"]];
        return (
          <SourceDisplay
            showText={props.activeReference?.id === rowId}
            relatedItems={relatedData}
            searchText={props.searchText}
            color="blue"
            setActiveSearchDocId={setActiveSearchDocId}
            activeSearchDocId={activeSearchDocId}
          />
        );
      },
      id: "dhcs-contract",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="DMHC" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["id"];
        const relatedData = getRelatedReferenceData(
          row.original.related,
          "KKA"
        );
        return (
          <SourceDisplay
            showText={props.activeReference?.id === rowId}
            relatedItems={relatedData}
            searchText={props.searchText}
            color="red"
            setActiveSearchDocId={setActiveSearchDocId}
            activeSearchDocId={activeSearchDocId}
          />
        );
      },
      id: "kka",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="NCQA" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["id"];
        const relatedData = getRelatedReferenceData(
          row.original.related,
          "NCQA HPA 2024"
        );
        return (
          <SourceDisplay
            showText={props.activeReference?.id === rowId}
            relatedItems={relatedData}
            searchText={props.searchText}
            color="orange"
            setActiveSearchDocId={setActiveSearchDocId}
            activeSearchDocId={activeSearchDocId}
          />
        );
      },
      id: "ncqa",
    },
    {
      header: ({ column }: any) => {
        return <HeaderCell column={column} columnName="CMS" />;
      },
      cell: ({ row }: any) => {
        const rowId = row.original["dhcs_contract"]["id"];
        const relatedData = getRelatedReferenceData(
          row.original.related,
          "Others"
        );
        return (
          <SourceDisplay
            showText={props.activeReference?.id === rowId}
            relatedItems={relatedData}
            searchText={props.searchText}
            color="green"
            setActiveSearchDocId={setActiveSearchDocId}
            activeSearchDocId={activeSearchDocId}
          />
        );
      },
      id: "cms",
    },
  ];

  return (
    <div className="relative">
      <div className="space-y-4">
        <DataTable
          columns={columns}
          data={sortedData}
          paginationAtTop={true}
          headerChildren={props.children}
          paginationParams={{ pageSize: 10, pageIndex: 0 }}
          totalRecordCount={sortedData.length}
          tableWrapperClassName="overflow-y-auto h-[calc(100vh-180px)]"
          tableCellClassName="align-top"
        />
      </div>

      {activeSearchResult && (
        <DocView
          searchResult={activeSearchResult}
          setActiveSearchDocId={setActiveSearchDocId}
        />
      )}
    </div>
  );
};
