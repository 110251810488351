import { useContext, useEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { AuditQuestion, AuditQuestionReviewDoc, Citation } from "../../types";
import { useParams } from "react-router-dom";
import {
  auditReviewLoadDocs,
  generateAuditReview,
  getAuditQuestionAnswer,
  getAuditReviewDocs,
  updateAuditReviewStatus,
} from "../../utils/apiCalls";
import { useAuthInfo } from "@propelauth/react";
import { toast } from "sonner";
import { DocViewerCitation } from "../../components/DocViewer";
import { Button } from "../../shadcn/components/button";
import {
  CheckCircledIcon,
  CopyIcon,
  ExclamationTriangleIcon,
  Pencil1Icon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import { Separator } from "../../shadcn/components/separator";
import Markdown from "react-markdown";
import { cn } from "../../shadcn/lib/utils";
import { Badge } from "../../shadcn/components/badge";
import {
  AnswerNav,
  BreadcrumbNav,
  QuestionToast,
} from "../../components/AuditPageComponents";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { StatusSelector } from "../../components/StatusSelector";
import { loadAndFormatDate } from "../../utils/format";

const ReviewStatusBadge = (props: { reviewStatus: string }) => {
  let badge;
  switch (props.reviewStatus) {
    case "ok":
      badge = (
        <Badge className="bg-green-500 text-white">
          <CheckCircledIcon className="w-4 h-4 mr-2" />
          Ok
        </Badge>
      );
      break;
    case "additional_review":
    case "found_issues":
      badge = (
        <Badge className="bg-orange-500 text-white">
          <ExclamationTriangleIcon className="w-4 h-4 mr-2" />
          Needs Review
        </Badge>
      );
      break;
  }

  return badge;
};

const filterDuplicates = (citations: Citation[]): Citation[] => {
  return citations
    .filter(
      (citation, index, self) =>
        index ===
        self.findIndex(
          (c) =>
            c.page === citation.page &&
            c.text.replace(/\s+/g, " ").trim() ===
              citation.text.replace(/\s+/g, " ").trim()
        )
    )
    .sort((a, b) => {
      if (a.page !== b.page) {
        return a.page - b.page;
      }
      return (a.start_index || 0) - (b.start_index || 0);
    })
    .map((citation) => {
      const citationId = citation.id || `cit-${citation.text.slice(0, 14)}`;
      return {
        ...citation,
        id: citationId,
      };
    });
};

const ReviewDocView = (props: {
  reviewDoc: AuditQuestionReviewDoc;
  activeDocId: string | null;
  setActiveDocId: React.Dispatch<React.SetStateAction<string | null>>;
  activeCitationId: string;
  setActiveCitationId: React.Dispatch<React.SetStateAction<string>>;
  setReviewDocs: React.Dispatch<React.SetStateAction<AuditQuestionReviewDoc[]>>;
}) => {
  const { auditResourceId, questionId } = useParams();
  const authInfo = useAuthInfo();
  const activeDoc = props.activeDocId === props.reviewDoc.doc_id;

  const handleStatusChange = async (value: string) => {
    if (!value) {
      switch (props.reviewDoc.review_status) {
        case "ok":
          value = "additional_review";
          break;
        case "additional_review":
          value = "ok";
          break;
      }
    }
    props.setReviewDocs((prev) => {
      return prev.map((reviewDoc) => {
        if (reviewDoc.id === props.reviewDoc.id) {
          return {
            ...reviewDoc,
            review_status: value,
            review_status_updated_by: {
              id: authInfo.user?.userId ?? "",
              email: authInfo.user?.email ?? "",
              first_name: authInfo.user?.firstName ?? "",
              last_name: authInfo.user?.lastName ?? "",
            },
            review_status_updated_at: new Date().toISOString(),
          };
        }
        return reviewDoc;
      });
    });
    const response = await updateAuditReviewStatus(
      auditResourceId ?? "",
      questionId ?? "",
      props.reviewDoc.id,
      value,
      authInfo.accessToken ?? null
    );
    if (response === false) {
      toast.error("Failed to update review status, please try again");
    }
  };

  return (
    <div
      className={cn(
        "p-5 rounded-md space-y-5",
        activeDoc ? "bg-gray-300" : "bg-white hover:bg-gray-200 cursor-pointer"
      )}
      onClick={() => {
        props.setActiveDocId(props.reviewDoc.doc_id);
      }}
    >
      <div className="flex justify-between">
        <div className="w-[calc(100%-300px)]">
          <div className="font-semibold text-sm truncate text-ellipsis">
            {props.reviewDoc.doc_name}
          </div>
        </div>
        {props.reviewDoc.review_status &&
          (!activeDoc ? (
            <ReviewStatusBadge reviewStatus={props.reviewDoc.review_status} />
          ) : (
            <div className="flex items-center space-x-2">
              {props.reviewDoc.review_status_updated_by && (
                <div className="text-sm text-gray-500">
                  Updated by{" "}
                  {props.reviewDoc.review_status_updated_by.first_name}{" "}
                  {props.reviewDoc.review_status_updated_by.last_name}
                </div>
              )}
              <StatusSelector
                options={[
                  {
                    value: "ok",
                    label: "Ok",
                    className:
                      "data-[state=on]:bg-green-400 data-[state=off]:hover:bg-green-100",
                  },
                  {
                    value: "additional_review",
                    label: "Needs Review",
                    className:
                      "data-[state=on]:bg-orange-400 data-[state=off]:hover:bg-orange-100",
                  },
                ]}
                status={props.reviewDoc.review_status}
                handleStatusChange={handleStatusChange}
              />
            </div>
          ))}
      </div>
      {props.reviewDoc.review && (
        <Markdown
          className={cn(
            "prose max-w-none mt-4",
            props.activeDocId === props.reviewDoc.doc_id &&
              "[&_li]:marker:text-black"
          )}
        >
          {props.reviewDoc.review}
        </Markdown>
      )}
      {activeDoc && (
        <div className="flex items-center space-x-1">
          {filterDuplicates(props.reviewDoc.citations).map(
            (citation, index) => {
              const isSelected = citation.id === props.activeCitationId;
              return (
                <Tooltip key={citation.id}>
                  <TooltipTrigger>
                    <Button
                      variant={isSelected ? "default" : "outline"}
                      size="icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (!isSelected) {
                          props.setActiveCitationId(citation.id!);
                        } else {
                          props.setActiveCitationId("");
                        }
                      }}
                    >
                      {index + 1}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-[300px] max-h-[190px] overflow-hidden">
                    <div className="line-clamp-6">{citation.text}</div>
                  </TooltipContent>
                </Tooltip>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export const ReviewView = (props: {
  question: AuditQuestion;
  setQuestion: React.Dispatch<React.SetStateAction<AuditQuestion | null>>;
  setReviewDocs: React.Dispatch<React.SetStateAction<AuditQuestionReviewDoc[]>>;
}) => {
  const { auditResourceId, questionId } = useParams();
  const authInfo = useAuthInfo();
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [loadReviewDocsLoading, setLoadReviewDocsLoading] =
    useState<boolean>(false);
  const cancelRef = useRef<boolean>(false);

  const handleLoadReviewDocs = async () => {
    setLoadReviewDocsLoading(true);
    const reviewDocs = await auditReviewLoadDocs(
      auditResourceId ?? "",
      questionId ?? "",
      authInfo.accessToken ?? null
    );
    if (reviewDocs !== null) {
      props.setReviewDocs(reviewDocs);
    } else {
      toast.error("Failed to load review docs, please try again");
    }
    setLoadReviewDocsLoading(false);
  };

  const generateReview = async () => {
    cancelRef.current = false;
    setGenerateLoading(true);
    try {
      for await (const output of generateAuditReview(
        auditResourceId ?? "",
        questionId ?? "",
        authInfo.accessToken ?? null
      )) {
        if (cancelRef.current) {
          break;
        }
        props.setReviewDocs(output.review_docs);
        props.setQuestion((prev) => {
          if (prev) {
            return { ...prev, review_summary: output.review_summary };
          }
          return prev;
        });
      }
      toast.success("Review generated successfully");
    } catch (error: any) {
      console.error("There was an error generating the review", error);
      toast.error("Unable to generate review, please try again");
    } finally {
      setGenerateLoading(false);
    }
  };

  const copyReviewSummaryToClipboard = () => {
    if (props.question.review_summary) {
      navigator.clipboard.writeText(props.question.review_summary);
      toast.success("Copied to clipboard");
    }
  };

  useEffect(() => {
    cancelRef.current = true;
  }, [questionId]);

  return (
    <>
      <div className="flex justify-between">
        <div className="font-semibold text-lg">Review Summary</div>
        <div className="space-x-2 flex-items-center">
          <Button
            variant="outline"
            onClick={handleLoadReviewDocs}
            disabled={loadReviewDocsLoading}
          >
            <ReloadIcon
              className={cn(
                "w-4 h-4 mr-2",
                loadReviewDocsLoading && "animate-spin"
              )}
            />
            <span className="text-sm">Load Docs</span>
          </Button>
          <Button variant="outline" onClick={generateReview}>
            {generateLoading ? (
              <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <Pencil1Icon className="w-4 h-4 mr-2" />
            )}
            <span className="text-sm">Review</span>
          </Button>
          {props.question.review_summary && (
            <Button variant="outline" onClick={copyReviewSummaryToClipboard}>
              <CopyIcon className="w-4 h-4 mr-2" />
              <span className="text-sm">Copy</span>
            </Button>
          )}
        </div>
      </div>
      {props.question.review_summary ? (
        <Markdown className="prose max-w-none mt-4">
          {props.question.review_summary}
        </Markdown>
      ) : props.question.review_docs_available ? (
        <div className="text-gray-500">
          No review summary generated yet, click review to generate
        </div>
      ) : (
        <div className="text-red-500">
          No documents have been linked to this question, please contact support
        </div>
      )}
    </>
  );
};

export const AuditAnswerReviewView = () => {
  const { auditId, auditResourceId, questionId } = useParams();
  const authInfo = useAuthInfo();
  const [question, setQuestion] = useState<AuditQuestion | null>(null);
  const [reviewDocs, setReviewDocs] = useState<AuditQuestionReviewDoc[]>([]);
  const [activeDocId, setActiveDocId] = useState<string | null>(null);
  const [previousQuestionId, setPreviousQuestionId] = useState<string | null>(
    null
  );
  const [nextQuestionId, setNextQuestionId] = useState<string | null>(null);
  const [activeCitationId, setActiveCitationId] = useState<string>("");
  const { setCitations, setPageNumber } = useContext(DocViewerContext);

  useEffect(() => {
    if (auditResourceId && questionId) {
      getAuditQuestionAnswer(
        auditResourceId,
        questionId,
        authInfo.accessToken ?? null
      ).then((res) => {
        if (res !== null) {
          setQuestion(res.question);
          setPreviousQuestionId(res.previous_question_id);
          setNextQuestionId(res.next_question_id);
        } else {
          toast.error("Failed to load existing answer, please refresh");
        }
      });
    }
  }, [auditResourceId, questionId]);

  useEffect(() => {
    if (question?.id) {
      getAuditReviewDocs(
        auditResourceId ?? "",
        questionId ?? "",
        authInfo.accessToken ?? null
      ).then((res) => {
        if (res !== null) {
          setReviewDocs(res);
        } else {
          toast.error("Failed to load review docs, please refresh");
        }
      });
    }
  }, [question?.id]);

  useEffect(() => {
    const activeDoc = reviewDocs.find(
      (reviewDoc) => reviewDoc.doc_id === activeDocId
    );
    if (activeDoc && activeDoc.citations.length > 0) {
      const citationsToDisplay = filterDuplicates(activeDoc.citations);
      if (
        !activeCitationId ||
        !citationsToDisplay.some((citation) => citation.id === activeCitationId)
      ) {
        setActiveCitationId(activeDoc.citations[0].id!);
      } else {
        setCitations(
          filterDuplicates(activeDoc.citations).map((citation) => {
            const isSelected = citation.id === activeCitationId;
            if (isSelected) {
              setPageNumber(citation.page);
            }
            return {
              id: citation.id!,
              match: citation.text,
              exactMatch: false,
              page: citation.page,
              className: `bg-highlight-${isSelected ? "default" : "inactive"}`,
              onClick: () => {
                setActiveCitationId(citation.id!);
              },
            };
          })
        );
      }
    } else {
      setActiveCitationId("");
      setPageNumber(0);
    }
  }, [activeDocId, activeCitationId]);

  return (
    <Layout pageName="Audits">
      <BreadcrumbNav
        auditId={auditId ?? ""}
        auditResourceId={auditResourceId ?? ""}
        review={true}
      />
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel
          defaultSize={60}
          minSize={40}
          maxSize={60}
          id="resource-panel"
          order={2}
        >
          <div className="space-y-4 pb-10 pl-1 pr-5 h-[calc(100vh-165px)] overflow-y-auto">
            <Separator />
            {question && (
              <div className="space-y-2 pb-4">
                <AnswerNav
                  previousQuestionId={previousQuestionId}
                  nextQuestionId={nextQuestionId}
                  questionId={questionId ?? ""}
                  auditId={auditId ?? ""}
                  auditResourceId={auditResourceId ?? ""}
                  review={true}
                />
                <QuestionToast questionText={question.question}>
                  <div className="font-semibold underline text-lg">
                    {question.section_index}.&nbsp;&nbsp;
                    {question.section_title}
                  </div>
                  <div className="text-sm whitespace-pre-line pb-4">
                    {question.question_index}.&nbsp;&nbsp;{question.question}
                  </div>
                </QuestionToast>
              </div>
            )}
            <Separator />
            {question && (
              <>
                <ReviewView
                  question={question}
                  setQuestion={setQuestion}
                  setReviewDocs={setReviewDocs}
                />
                <Separator />
                <div className="font-semibold text-lg">
                  Submitted Documents ({reviewDocs.length})
                </div>
                <div className="space-y-2">
                  {reviewDocs.map((reviewDoc) => (
                    <ReviewDocView
                      key={reviewDoc.id}
                      reviewDoc={reviewDoc}
                      activeDocId={activeDocId}
                      setActiveDocId={setActiveDocId}
                      activeCitationId={activeCitationId}
                      setActiveCitationId={setActiveCitationId}
                      setReviewDocs={setReviewDocs}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </ResizablePanel>
        {activeDocId && <ResizableHandle withHandle className="mx-4" />}
        <ResizablePanel
          defaultSize={40}
          minSize={40}
          maxSize={60}
          id="doc-view-panel"
          order={3}
        >
          {activeDocId && (
            <DocViewerCitation
              docId={activeDocId}
              className="h-[calc(100vh-260px)]"
              hideAtlasWidget={true}
            />
          )}
          {(!question || question.citations.length === 0) && (
            <div className="flex justify-center items-center h-full">
              <div className="text-center text-gray-500">
                No Document Selected
              </div>
            </div>
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </Layout>
  );
};
