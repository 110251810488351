import { useSearchParams } from "react-router-dom";
import { Badge } from "../../shadcn/components/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import {
  ActionItem,
  Citation,
  Department,
  Requirement,
  RequirementTag,
  tagToLabelMap,
} from "../../types";
import { citationSortOrder, loadAndFormatTime } from "../../utils/format";
import { DataTable, HeaderCell } from "../Table";
import { Search } from "../../shadcn/components/search";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RequirementContext } from "../../contexts/RequirementContext";
import { SelectFilter, TooltipNumber } from "../FilterUtils";
import { RequirementTextDisplay } from "./GapDocDisplayUtils";

const SearchReport = (props: {
  setSearchTerm: (searchTerm: string) => void;
}) => {
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const onSearchChange = useCallback(
    (e: any) => {
      const value = e.target.value.trimEnd();

      // Clear the previous timeout
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      // Set a new timeout to update the search term after 1 second
      searchTimeoutRef.current = setTimeout(() => {
        props.setSearchTerm(value);
      }, 300);
    },
    [props.setSearchTerm]
  );

  return (
    <Search
      onChangeHandler={onSearchChange}
      width="200px"
      placeholder="Search..."
    />
  );
};

const getPage = (requirement: Requirement) => {
  return requirement.citations.length > 0
    ? Math.min(...requirement.citations.map((citation) => citation.page))
    : 1;
};

const getDepartments = (requirement: Requirement) => {
  const uniqueDepartments = [
    ...new Map(
      requirement.assignees
        .concat(
          requirement.action_items.flatMap(
            (action_item: ActionItem) => action_item.assignees
          )
        )
        .filter(Boolean)
        .map((department: Department) => [department.name, department])
    ).values(),
  ] as Department[];

  const departmentsToShow = uniqueDepartments
    .map((department: Department) => department.name)
    .sort((a, b) => a.localeCompare(b));

  return departmentsToShow;
};

const getDocuments = (requirement: Requirement) => {
  const uniqueDocuments = [
    ...new Map(
      requirement.impacted_documents
        .concat(
          requirement.action_items.flatMap(
            (action_item: ActionItem) => action_item.citations
          )
        )
        .filter(Boolean)
        .map((citation: Citation) => [citation.doc_id, citation])
    ).values(),
  ] as Citation[];

  const documentsToShow = uniqueDocuments
    .map((citation: Citation) => citation.doc_name!)
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));

  return documentsToShow;
};

const getReferenceDocuments = (requirement: Requirement) => {
  const uniqueDocuments = [
    ...new Map(
      requirement.reference_documents
        .concat(
          requirement.action_items.flatMap(
            (action_item: ActionItem) => action_item.reference_documents
          )
        )
        .filter(Boolean)
        .map((citation: Citation) => [citation.doc_id, citation])
    ).values(),
  ] as Citation[];

  const referenceDocumentsToShow = uniqueDocuments
    .map((citation: Citation) => citation.doc_name!)
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));

  return referenceDocumentsToShow;
};

const getFilterCounts = (filterValuesAll: string[]) => {
  const filterCounts: Record<string, number> = filterValuesAll.reduce(
    (acc, value) => {
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>
  );
  return filterCounts;
};

const LabelBadge = (props: { label: RequirementTag; color: string }) => {
  return <Badge className={props.color}>{tagToLabelMap[props.label]}</Badge>;
};

export const RequirementTable = () => {
  const { requirements, setViewType, setActiveRequirementId, viewType } =
    useContext(RequirementContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [labelFilter, setLabelFilter] = useState<string[]>([]);
  const [departmentFilter, setDepartmentFilter] = useState<string[]>([]);
  const [impactedDocumentFilter, setImpactedDocumentFilter] = useState<
    string[]
  >([]);
  const [referenceDocumentFilter, setReferenceDocumentFilter] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (viewType === "table") {
      setActiveRequirementId("");
      setSearchParams(
        (prev: URLSearchParams) => {
          prev.delete("requirementId");
          prev.set("activeTab", "requirements");
          prev.set("viewType", "table");
          return prev;
        },
        {
          replace: true,
        }
      );
    }
  }, [viewType]);

  const columns: any[] = useMemo(
    () => [
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Page" />;
        },
        cell: ({ row }: any) => {
          return <div className="text-center">{getPage(row.original)}</div>;
        },
        id: "page",
        accessorKey: "page",
        sortingFn: (rowA: any, rowB: any, columnId: string) => {
          return getPage(rowA.original) - getPage(rowB.original);
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Requirement" />;
        },
        cell: ({ row }: any) => {
          return (
            <Tooltip>
              <TooltipTrigger>
                <div
                  className="text-left text-blue-500 underline"
                  onClick={() => {
                    setActiveRequirementId(row.original.id);
                    setViewType("list");
                  }}
                >
                  <RequirementTextDisplay
                    text={row.original.text}
                    maxWords={20}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="w-[300px]">
                  <RequirementTextDisplay text={row.original.text} />
                </div>
              </TooltipContent>
            </Tooltip>
          );
        },
        id: "requirement",
        accessorKey: "text",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Label" />;
        },
        cell: ({ row }: any) => {
          switch (row.original.tag) {
            case "actionable":
              return <LabelBadge label="actionable" color="bg-red-400" />;
            case "informational_only":
              return (
                <LabelBadge label="informational_only" color="bg-yellow-400" />
              );
            case "not_applicable":
              return <LabelBadge label="not_applicable" color="bg-gray-400" />;
            case "no_substantive_changes":
              return (
                <LabelBadge
                  label="no_substantive_changes"
                  color="bg-blue-400"
                />
              );
            default:
              return row.original.tag;
          }
        },
        id: "requirement-label",
        accessorKey: "tag",
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Departments" />;
        },
        cell: ({ row }: any) => {
          const departmentsToShow = getDepartments(row.original);

          return <TooltipNumber items={departmentsToShow} />;
        },
        id: "impacted-departments",
        accessorKey: "impacted-departments",
        sortingFn: (rowA: any, rowB: any, columnId: string) => {
          return (
            getDepartments(rowA.original).length -
            getDepartments(rowB.original).length
          );
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Actions" />;
        },
        cell: ({ row }: any) => {
          return (
            <TooltipNumber
              items={row.original.action_items.map(
                (action_item: ActionItem) => action_item.text
              )}
            />
          );
        },
        id: "action-items",
        accessorKey: "action-items",
        sortingFn: (rowA: any, rowB: any, columnId: string) => {
          return (
            rowA.original.action_items.length -
            rowB.original.action_items.length
          );
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Docs" />;
        },
        cell: ({ row }: any) => {
          const uniqueDocuments = [
            ...new Map(
              row.original.impacted_documents
                .concat(
                  row.original.action_items.flatMap(
                    (action_item: ActionItem) => action_item.citations
                  )
                )
                .filter(Boolean)
                .map((citation: Citation) => [citation.doc_id, citation])
            ).values(),
          ] as Citation[];

          const documentsToShow = uniqueDocuments
            .map((citation: Citation) => citation.doc_name!)
            .filter(Boolean)
            .sort((a, b) => a.localeCompare(b));

          return <TooltipNumber items={documentsToShow} />;
        },
        id: "impacted-documents",
        accessorKey: "impacted-documents",
        sortingFn: (rowA: any, rowB: any, columnId: string) => {
          return (
            getDocuments(rowA.original).length -
            getDocuments(rowB.original).length
          );
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Refs" />;
        },
        cell: ({ row }: any) => {
          const uniqueDocuments = [
            ...new Map(
              row.original.reference_documents
                .concat(
                  row.original.action_items.flatMap(
                    (action_item: ActionItem) => action_item.reference_documents
                  )
                )
                .filter(Boolean)
                .map((citation: Citation) => [citation.doc_id, citation])
            ).values(),
          ] as Citation[];

          const referenceDocumentsToShow = uniqueDocuments
            .map((citation: Citation) => citation.doc_name!)
            .filter(Boolean)
            .sort((a, b) => a.localeCompare(b));

          return <TooltipNumber items={referenceDocumentsToShow} />;
        },
        id: "reference-documents",
        accessorKey: "reference-documents",
        sortingFn: (rowA: any, rowB: any, columnId: string) => {
          return (
            getReferenceDocuments(rowA.original).length -
            getReferenceDocuments(rowB.original).length
          );
        },
      },
      {
        header: ({ column }: any) => {
          return <HeaderCell column={column} columnName="Updated" />;
        },
        cell: ({ row }: any) => {
          return (
            <div className="text-center">
              {loadAndFormatTime(row.original.updated_at)}
            </div>
          );
        },
        id: "updated-at",
        accessorKey: "updated_at",
      },
    ],
    []
  );

  const searchFilterData = requirements.filter(
    (requirement) =>
      requirement.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      requirement.action_items.some((action_item) =>
        action_item.text.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      !searchTerm
  );

  const labelFilterCounts = getFilterCounts(
    searchFilterData.map((requirement) => requirement.tag || "None")
  );

  const labelFilterData = searchFilterData.filter(
    (requirement) =>
      labelFilter.includes(requirement.tag || "None") ||
      labelFilter.length === 0
  );

  const departmentFilterCounts = getFilterCounts(
    labelFilterData.flatMap((requirement) => getDepartments(requirement))
  );

  const departmentFilterData = labelFilterData.filter(
    (requirement) =>
      getDepartments(requirement).some((department) =>
        departmentFilter.includes(department)
      ) || departmentFilter.length === 0
  );

  const impactedDocumentFilterCounts = getFilterCounts(
    departmentFilterData.flatMap((requirement) => getDocuments(requirement))
  );

  const impactedDocumentFilterData = departmentFilterData.filter(
    (requirement) =>
      getDocuments(requirement).some((document) =>
        impactedDocumentFilter.includes(document)
      ) || impactedDocumentFilter.length === 0
  );

  const referenceDocumentFilterCounts = getFilterCounts(
    impactedDocumentFilterData.flatMap((requirement) =>
      getReferenceDocuments(requirement)
    )
  );

  const referenceDocumentFilterData = impactedDocumentFilterData.filter(
    (requirement) =>
      getReferenceDocuments(requirement).some((document) =>
        referenceDocumentFilter.includes(document)
      ) || referenceDocumentFilter.length === 0
  );

  return (
    <DataTable
      tableWrapperClassName="overflow-y-auto h-[calc(100vh-270px)]"
      columns={columns}
      data={referenceDocumentFilterData.sort(citationSortOrder)}
      paginationAtTop={true}
      paginationParams={{
        pageSize: 50,
        pageIndex: 0,
      }}
      totalRecordCount={referenceDocumentFilterData.length}
      headerChildren={
        <div className="flex items-center space-x-2">
          <SearchReport setSearchTerm={setSearchTerm} />
          <SelectFilter
            title="Label"
            filterCounts={labelFilterCounts}
            activeFilter={labelFilter}
            setActiveFilter={setLabelFilter}
            nameFormatter={(name: string) =>
              name !== "None" ? tagToLabelMap[name as RequirementTag] : "None"
            }
          />
          <SelectFilter
            title="Departments"
            filterCounts={departmentFilterCounts}
            activeFilter={departmentFilter}
            setActiveFilter={setDepartmentFilter}
          />
          <SelectFilter
            title="Docs"
            filterCounts={impactedDocumentFilterCounts}
            activeFilter={impactedDocumentFilter}
            setActiveFilter={setImpactedDocumentFilter}
          />
          <SelectFilter
            title="Refs"
            filterCounts={referenceDocumentFilterCounts}
            activeFilter={referenceDocumentFilter}
            setActiveFilter={setReferenceDocumentFilter}
          />
        </div>
      }
    />
  );
};
