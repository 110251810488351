import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../shadcn/components/dialog";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { Citation, SearchDocName } from "../../types";
import { DocViewerCitation } from "../DocViewer";
import { CitationView } from "./Citations";
import { Button } from "../../shadcn/components/button";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { toggleAtlasWidget } from "../../utils/cookies";
import { PdfCitation } from "../PdfViewer/PdfHighlighter/types";
import { useModalContext } from "../../contexts/ActiveModalContext";

export const CitationDialog = (props: {
  persistUrl: {
    prefix: string;
    suffix: string;
  };
  citations: Citation[];
  headerChildren: ReactNode;
  question: string;
  onNewCitationSuccess: (
    citation: Citation,
    existingCitation: Citation | null
  ) => void;
  onDeleteCitationSuccess: (citation: Citation) => void;
  onClose: () => void;
  labelText: string;
  allowedDocTypeIds: string[];
  hideAtlasWidget?: boolean;
  fetchRelevantDocs?: () => Promise<SearchDocName[] | null>;
  buttonLabel: string;
}) => {
  const [open, setOpen] = useState(false);
  const [activeCitationId, setActiveCitationId] = useState<string | null>(
    props.citations.length > 0 ? props.citations[0].id! : null
  );
  const [relevantDocs, setRelevantDocs] = useState<SearchDocName[]>([]);
  const [highlightedCitation, setHighlightedCitation] =
    useState<PdfCitation | null>(null);
  const { docToView, setDocToView } = useContext(DocViewerContext);
  const activeModalRef = useRef<HTMLDivElement>(null);

  const { setActiveModalRef } = useModalContext();
  useEffect(() => {
    if (open && props.fetchRelevantDocs) {
      props.fetchRelevantDocs().then((response) => {
        if (response !== null) {
          setRelevantDocs(response);
        } else {
          console.error("failed to fetch relevant documents");
        }
      });
      setActiveModalRef(activeModalRef);
    }
    if (!open) {
      setActiveModalRef(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          props.onClose();
        } else {
          setActiveCitationId(
            props.citations.length > 0 ? props.citations[0].id! : null
          );
        }
        setOpen(open);
        if (props.hideAtlasWidget) {
          toggleAtlasWidget(open);
        }
      }}
    >
      <DialogTrigger>
        <Button variant="outline" size="sm">
          {props.buttonLabel}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[90%] h-[90%]" ref={activeModalRef}>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={60}
            minSize={40}
            maxSize={60}
            id="resource-panel"
            order={2}
          >
            <div className="h-[calc(100vh-100px)] overflow-y-auto">
              <CitationView
                persistUrl={props.persistUrl}
                citations={props.citations}
                highlightedCitation={highlightedCitation}
                setHighlightedCitation={setHighlightedCitation}
                onDeleteCitationSuccess={(citation) => {
                  props.onDeleteCitationSuccess(citation);
                }}
                onNewCitationSuccess={(citation, existingCitation) => {
                  props.onNewCitationSuccess(citation, existingCitation);
                  setActiveCitationId(citation.id!);
                }}
                setDoc={(ac) => {
                  if (ac !== null && ac.doc_id !== docToView?.docId) {
                    setDocToView({
                      docId: ac.doc_id!,
                    });
                  }
                }}
                relevantDocs={relevantDocs}
                hideAtlasWidget={false}
                activeCitationId={activeCitationId}
                setActiveCitationId={setActiveCitationId}
                labelText={props.labelText}
                question={props.question}
                allowedDocTypeIds={props.allowedDocTypeIds}
                headerChildren={props.headerChildren}
                simpleView={true}
              />
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle className="mx-4" />
          <ResizablePanel
            defaultSize={40}
            minSize={40}
            maxSize={60}
            id="doc-view-panel"
            order={3}
          >
            {docToView?.docId && (
              <DocViewerCitation
                docId={docToView.docId}
                className="h-[calc(100vh-295px)]"
                hideAtlasWidget={props.hideAtlasWidget}
                onCitationsUpdate={(citations) => {
                  setHighlightedCitation(citations);
                }}
                parentModalRef={activeModalRef}
              />
            )}
          </ResizablePanel>
        </ResizablePanelGroup>
      </DialogContent>
    </Dialog>
  );
};
