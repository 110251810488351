import { useAuthInfo } from "@propelauth/react";
import { useParams, useSearchParams } from "react-router-dom";
import { RegulatoryDoc, Relationship } from "../../types";
import { useContext, useEffect, useState } from "react";
import { getRegulatoryDoc } from "../../utils/apiCalls";
import { toast } from "sonner";
import { Layout } from "../../components/Layout";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn/components/tabs";
import {
  ConfigurationControls,
  RegulatoryDocOverviewView,
} from "./RegulatoryDocOverviewView";
import { Badge } from "../../shadcn/components/badge";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn/components/avatar";
import { RegulatoryDocContent } from "./CustomDocFeedContent";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { ActionItemView } from "../../components/GapAnalysis/ActionItemView";
import { UserContext } from "../../contexts/UserContext";
import { LoadingView } from "../../components/Loading";
import {
  RequirementControlsHeader,
  RequirementView,
} from "../../components/GapAnalysis/RequirementView";
import { RequirementContext } from "../../contexts/RequirementContext";

const AttachmentDisplay = (props: { attachments: Relationship[] }) => {
  return (
    <div className="space-y-6">
      {props.attachments.map((relationship) => (
        <div key={relationship.id} className="flex space-x-2">
          <Avatar className="h-6 w-6 flex-shrink-0">
            <AvatarImage
              src={`/attachment-icon.png`} // TODO: Use file type logo (pdf, xlsx, etc.)
            />
            <AvatarFallback>•</AvatarFallback>
          </Avatar>

          <div className="cursor-pointer underline text-[#007acc] transition-color duration-300 ease-in-out overflow-hidden">
            <a href={`/doc-chat?docId=${relationship.id}`}>
              {relationship.name}
            </a>
          </div>
        </div>
      ))}
      {props.attachments.length === 0 && (
        <div className="text-center text-gray-500">No attachments</div>
      )}
    </div>
  );
};

const PageHeader = (props: { regulatoryDoc: RegulatoryDoc }) => {
  return (
    <div>
      <div className="flex items-center space-x-4">
        <img
          src={`/${props.regulatoryDoc.doc_type.agency.toLowerCase()}.png`}
          alt={props.regulatoryDoc.doc_type.agency}
          className="w-28 rounded-lg"
        />
        <div className="space-y-1 overflow-hidden whitespace-nowrap">
          <div className="text-2xl font-bold truncate text-ellipsis">
            {props.regulatoryDoc.doc_identifier}
          </div>
          {props.regulatoryDoc.title !== props.regulatoryDoc.doc_identifier && (
            <div className="text-lg text-gray-500">
              {props.regulatoryDoc.title}
            </div>
          )}
        </div>
      </div>
      <RegulatoryDocContent
        regulatoryDoc={props.regulatoryDoc}
        feedView={false}
      />
    </div>
  );
};

export const RegulatoryDocView = () => {
  const authInfo = useAuthInfo();
  const {
    activeTab,
    setActiveTab,
    requirements,
    setRequirements,
    requirementsLoading,
    loadRequirements,
  } = useContext(RequirementContext);
  const { docToView, setDocToView, setCitations, setPageNumber } =
    useContext(DocViewerContext);
  const { regDocGapDocTypes } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { regulatoryDocId } = useParams<{
    regulatoryDocId: string;
  }>();
  const [regulatoryDoc, setRegulatoryDoc] = useState<RegulatoryDoc | null>(
    null
  );
  const attachments = regulatoryDoc?.relationships.filter(
    (relationship) =>
      relationship.relationship_type === "attachment" &&
      relationship.direction == "primary"
  );

  const impactedDepartments = [
    ...new Map(
      requirements
        .flatMap((requirement) => requirement.assignees)
        .concat(
          requirements
            .flatMap((requirement) => requirement.action_items)
            .flatMap((action_item) => action_item.assignees)
        )
        .filter(Boolean)
        .map((assignee) => [assignee.id, assignee])
    ).values(),
  ].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (regulatoryDocId) {
      getRegulatoryDoc(regulatoryDocId, authInfo.accessToken ?? null).then(
        (doc) => {
          if (doc !== null) {
            setRegulatoryDoc(doc);
            if (docToView?.docId !== doc.doc_id) {
              setDocToView({
                docId: doc.doc_id,
              });
              setCitations([]);
              setPageNumber(1);
            }
          } else {
            toast.error("Failed to load regulatory doc");
          }
        }
      );
    }
  }, [regulatoryDocId]);

  useEffect(() => {
    if (regulatoryDoc?.doc_id) {
      loadRequirements(regulatoryDoc.doc_id);
    }
  }, [regulatoryDoc?.doc_id]);

  useEffect(() => {
    const tab = searchParams.get("activeTab");
    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    setSearchParams(
      (prev) => {
        prev.set("activeTab", activeTab);
        return prev;
      },
      {
        replace: true,
      }
    );
    setCitations([]);
    setPageNumber(1);
  }, [activeTab]);

  return (
    <Layout
      pageHeader={regulatoryDoc && <PageHeader regulatoryDoc={regulatoryDoc} />}
    >
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <div className="flex justify-between mb-4">
          <div className="flex items-center space-x-2">
            <TabsList className="bg-gray-200">
              <TabsTrigger className="h-8 px-6" value="overview">
                Overview
              </TabsTrigger>
              <TabsTrigger className="h-8 px-6" value="requirements">
                Requirements{" "}
                {requirements.length > 0 && (
                  <Badge className="ml-2 bg-gray-600">
                    {requirements.length}
                  </Badge>
                )}
              </TabsTrigger>
              <TabsTrigger className="h-8 px-6" value="actions">
                Action Items{" "}
                {requirements.flatMap((requirement) => requirement.action_items)
                  .length > 0 && (
                  <Badge className="ml-2 bg-gray-600">
                    {
                      requirements.flatMap(
                        (requirement) => requirement.action_items
                      ).length
                    }
                  </Badge>
                )}
              </TabsTrigger>
              <TabsTrigger className="h-8 px-6" value="attachments">
                Attachments{" "}
                {attachments && attachments.length > 0 && (
                  <Badge className="ml-2 bg-gray-600">
                    {attachments.length}
                  </Badge>
                )}
              </TabsTrigger>
            </TabsList>
          </div>
          <div className="flex items-center space-x-6">
            {activeTab === "overview" && regulatoryDoc && (
              <ConfigurationControls
                regulatoryDoc={regulatoryDoc}
                setRegulatoryDoc={setRegulatoryDoc}
              />
            )}
            <RequirementControlsHeader
              docId={regulatoryDoc?.doc_id ?? ""}
              requirementView={activeTab === "requirements"}
            />
          </div>
        </div>
        <TabsContent value="overview">
          {regulatoryDoc && (
            <RegulatoryDocOverviewView
              regulatoryDoc={regulatoryDoc}
              setRegulatoryDoc={setRegulatoryDoc}
              impactedDepartments={impactedDepartments}
            />
          )}
        </TabsContent>
        <TabsContent value="requirements">
          {regulatoryDoc && (
            <RequirementView
              actionItemRoutePrefix={`/regulatory-doc/overview/${regulatoryDoc.id}?activeTab=actions&actionItemId=`}
              docId={regulatoryDoc.doc_id}
            />
          )}
        </TabsContent>
        <TabsContent value="actions">
          {regulatoryDoc && !requirementsLoading && (
            <ActionItemView
              docId={regulatoryDoc.doc_id}
              requirements={requirements}
              setRequirements={setRequirements}
              allowedDocTypeIds={regDocGapDocTypes.map((docType) => docType.id)}
              requirementItemRoutePrefix={`/regulatory-doc/overview/${regulatoryDoc.id}?activeTab=requirements&requirementId=`}
            />
          )}
          {requirementsLoading && (
            <LoadingView customText="Loading requirements..." />
          )}
        </TabsContent>
        <TabsContent value="attachments">
          {regulatoryDoc && attachments && (
            <AttachmentDisplay attachments={attachments} />
          )}
        </TabsContent>
      </Tabs>
    </Layout>
  );
};
