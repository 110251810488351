import { createContext, useEffect, useState } from "react";
import { Requirement } from "../types";
import { useAuthInfo } from "@propelauth/react";
import { generateRequirement, getRequirements } from "../utils/apiCalls";
import { toast } from "sonner";
import { citationSortOrder, loadAndFormatTime } from "../utils/format";
import { HIGHLIGHTED_CITATION_ID } from "../components/PdfViewer";
import { useSearchParams } from "react-router-dom";

interface RequirementContextProps {
  requirements: Requirement[];
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  loadRequirements: (docId: string) => Promise<void>;
  addRequirement: (docId: string, pageNumber: number) => Promise<void>;
  generateRequirementLoading: boolean;
  requirementsLoading: boolean;
  viewType: "table" | "list";
  setViewType: React.Dispatch<React.SetStateAction<"table" | "list">>;
  activeRequirement: Requirement | undefined;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editingIndividualRequirementCitation: boolean;
  setEditingIndividualRequirementCitation: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  activeRequirementId: string;
  setActiveRequirementId: React.Dispatch<React.SetStateAction<string>>;
  individualRequirementActiveCitationIndex: number;
  setIndividualRequirementActiveCitationIndex: React.Dispatch<
    React.SetStateAction<number>
  >;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export const RequirementContext = createContext<RequirementContextProps>({
  requirements: [],
  setRequirements: () => {},
  loadRequirements: async () => {},
  addRequirement: async () => {},
  generateRequirementLoading: false,
  requirementsLoading: false,
  viewType: "table",
  setViewType: () => {},
  activeRequirement: undefined,
  activeRequirementId: "",
  setActiveRequirementId: () => {},
  editing: false,
  setEditing: () => {},
  editingIndividualRequirementCitation: false,
  setEditingIndividualRequirementCitation: () => {},
  individualRequirementActiveCitationIndex: 0,
  setIndividualRequirementActiveCitationIndex: () => {},
  activeTab: "",
  setActiveTab: () => {},
});

export const RequirementProvider = (props: {
  children: React.ReactNode;
  defaultActiveTab: string;
}) => {
  const { children } = props;
  const authInfo = useAuthInfo();
  const [activeRequirementId, setActiveRequirementId] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(false);
  const [
    editingIndividualRequirementCitation,
    setEditingIndividualRequirementCitation,
  ] = useState<boolean>(false);
  const [requirementsLoading, setRequirementsLoading] =
    useState<boolean>(false);
  const [requirements, setRequirements] = useState<Requirement[]>([]);
  const [viewType, setViewType] = useState<"table" | "list">("table");
  const [generateRequirementLoading, setGenerateRequirementLoading] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [
    individualRequirementActiveCitationIndex,
    setIndividualRequirementActiveCitationIndex,
  ] = useState(0);
  const [activeTab, setActiveTab] = useState<string>(props.defaultActiveTab);
  const activeRequirement = requirements.find(
    (requirement) => requirement.id === activeRequirementId
  );

  useEffect(() => {
    const paramViewType = searchParams.get("viewType");
    if (paramViewType) {
      setViewType(paramViewType as "table" | "list");
      if (paramViewType === "list") {
        const paramRequirementId = searchParams.get("requirementId");
        if (paramRequirementId) {
          setActiveRequirementId(paramRequirementId);
        }
      }
    }
  }, []);

  const loadRequirements = async (docId: string) => {
    setRequirementsLoading(true);
    getRequirements(docId, authInfo.accessToken ?? null).then((response) => {
      if (response !== null) {
        const newRequirements = response.requirements.sort(citationSortOrder);
        setRequirements(newRequirements);
        if (
          response.requirements.length > 0 &&
          searchParams.get("requirementId") === null
        ) {
          const mostRecentRequirement = response.requirements.reduce(
            (prev, current) => {
              const prevDate = new Date(prev.updated_at);
              const currentDate = new Date(current.updated_at);
              return currentDate > prevDate ? current : prev;
            }
          );

          const timestamp = loadAndFormatTime(mostRecentRequirement.updated_at);

          toast.message(`Continue where you left off?`, {
            description: timestamp,
            action: {
              label: "View",
              onClick: () => {
                setActiveTab("requirements");
                setViewType("list");
                setActiveRequirementId(mostRecentRequirement.id!);
              },
            },
            duration: 15000, // 15 seconds
            position: "top-center",
            closeButton: true,
          });
        }
      } else {
        toast.error("Failed to load requirements");
      }
      setRequirementsLoading(false);
    });
  };

  const addRequirement = async (docId: string, pageNumber: number) => {
    let response = null;

    // if there are existing requirements, generate a new one
    if (requirements.length > 0) {
      setGenerateRequirementLoading(true);
      response = await generateRequirement(
        docId,
        pageNumber,
        authInfo.accessToken ?? ""
      );
      if (response !== null && response.length === 0) {
        toast.info("No requirement to suggest");
      }
      setGenerateRequirementLoading(false);
    }
    let newRequirement: Requirement;

    // if the response is not null, use the first requirement in the response otherwise create a new one
    if (response !== null && response.length > 0) {
      newRequirement = response[0];
      newRequirement.id = "NEW";
      newRequirement.citations[0].id = HIGHLIGHTED_CITATION_ID;
    } else {
      newRequirement = {
        id: "NEW",
        text: "",
        reference_doc_types: activeRequirement?.reference_doc_types ?? null,
        citations: [],
        action_items: [],
        tag: null,
        impacted_documents: [],
        assignees: [],
        updated_at: new Date().toISOString().slice(0, -1),
        reference_documents: [],
      };
    }
    setRequirements([...requirements, newRequirement]);
    setActiveRequirementId("NEW");
    setEditing(true);
  };

  return (
    <RequirementContext.Provider
      value={{
        requirements,
        setRequirements,
        loadRequirements,
        addRequirement,
        generateRequirementLoading,
        requirementsLoading,
        viewType,
        setViewType,
        activeRequirement,
        editing,
        setEditing,
        editingIndividualRequirementCitation,
        setEditingIndividualRequirementCitation,
        activeRequirementId,
        setActiveRequirementId,
        individualRequirementActiveCitationIndex,
        setIndividualRequirementActiveCitationIndex,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </RequirementContext.Provider>
  );
};
