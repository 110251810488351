import { v4 as uuidv4 } from "uuid";
import { useAuthInfo } from "@propelauth/react";
import {
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  ActionItem,
  Citation,
  Department,
  Requirement,
  RequirementTag,
  SimpleUser,
  tagToLabelMap,
} from "../../types";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import {
  addCitation,
  autoPopulateRequirement,
  debounce,
  deleteCitation,
  generateActionItem,
  saveActionItem,
  saveAssignees,
  updateRequirements,
} from "../../utils/apiCalls";
import { toast } from "sonner";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { DocViewerCitation } from "../DocViewer";
import { Button } from "../../shadcn/components/button";
import {
  Pencil1Icon,
  PlusIcon,
  ReloadIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { cn } from "../../shadcn/lib/utils";
import { citationSortOrder, TimeAgo } from "../../utils/format";
import { RequirementDisplay } from "./GapDocDisplayUtils";
import { ConfirmActionItemDeletion } from "./ActionItemDialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import { MultiSelectControl } from "../MultiSelectControl";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../shadcn/components/tooltip";
import { Badge } from "../../shadcn/components/badge";
import { UserContext } from "../../contexts/UserContext";
import { Separator } from "../../shadcn/components/separator";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../shadcn/components/alert-dialog";
import { HighlightTooltipActionButton } from "../HighlightTooltipActionButton";
import {
  RequirementImpactedDocumentView,
  RequirementReferenceDocumentView,
} from "./RequirementDocumentView";
import { RequirementContext } from "../../contexts/RequirementContext";
import { SparkleIcon } from "lucide-react";
import { PdfCitation } from "../PdfViewer/PdfHighlighter/types";
import { HIGHLIGHTED_CITATION_ID } from "../PdfViewer";
import { AutoSavingTextArea } from "../AutoSavingTextArea";
import { getFilterCounts, SelectFilter } from "../FilterUtils";

const ImpactedDepartmentView = (props: {
  docId: string;
  requirement: Requirement;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  actionItemIds: string[];
  uniqueDepartments: Department[];
}) => {
  const authInfo = useAuthInfo();
  const { departments } = useContext(UserContext);

  const updateAssignees = debounce(async (assignees: Department[]) => {
    await saveAssignees(
      `gap-analysis/requirement/assignees/${props.docId}/${props.requirement.id}`,
      assignees,
      authInfo.accessToken ?? ""
    );
    for (const actionItem of props.requirement.action_items) {
      await saveAssignees(
        `gap-analysis/action-item/assignees/${props.docId}/${props.requirement.id}/${actionItem.id}`,
        assignees,
        authInfo.accessToken ?? ""
      );
    }
  }, 1000);

  return (
    <div className="space-y-3 bg-gray-300 rounded-b-md">
      <Separator className="bg-gray-200" />
      <div className="flex items-start justify-between">
        <div className="text-base font-semibold">Impacted Departments</div>
        <div style={{ transform: "scale(0.85)", transformOrigin: "right" }}>
          {" "}
          <MultiSelectControl
            title="Departments"
            items={departments}
            selectedItems={props.uniqueDepartments}
            selectItem={(item, isSelected) => {
              let newAssignees: Department[] = [];
              if (isSelected) {
                props.setRequirements((prev) => {
                  return prev.map((requirement) => {
                    if (requirement.id === props.requirement.id) {
                      newAssignees = [...props.uniqueDepartments, item];
                      return {
                        ...requirement,
                        assignees: [...props.uniqueDepartments, item],
                        action_items: requirement.action_items.map(
                          (action_item) => {
                            if (props.actionItemIds.includes(action_item.id)) {
                              return {
                                ...action_item,
                                assignees: [...props.uniqueDepartments, item],
                              };
                            }
                            return action_item;
                          }
                        ),
                      };
                    }
                    return requirement;
                  });
                });
              } else {
                props.setRequirements((prev) => {
                  return prev.map((requirement) => {
                    if (requirement.id === props.requirement.id) {
                      newAssignees = requirement.assignees.filter(
                        (department) => department.id !== item.id
                      );
                      return {
                        ...requirement,
                        assignees: requirement.assignees.filter(
                          (department) => department.id !== item.id
                        ),
                        action_items: requirement.action_items.map(
                          (action_item) => {
                            if (props.actionItemIds.includes(action_item.id)) {
                              return {
                                ...action_item,
                                assignees: action_item.assignees.filter(
                                  (department) => department.id !== item.id
                                ),
                              };
                            }
                            return action_item;
                          }
                        ),
                      };
                    }
                    return requirement;
                  });
                });
              }
              updateAssignees(newAssignees);
            }}
            clearSelectedItems={() => {
              props.setRequirements((prev) => {
                return prev.map((requirement) => {
                  if (requirement.id === props.requirement.id) {
                    return {
                      ...requirement,
                      assignees: [],
                      action_items: requirement.action_items.map(
                        (action_item) => {
                          if (props.actionItemIds.includes(action_item.id)) {
                            return {
                              ...action_item,
                              assignees: [],
                            };
                          }
                          return action_item;
                        }
                      ),
                    };
                  }
                  return requirement;
                });
              });
              updateAssignees([]);
            }}
          />
        </div>
      </div>
      <div
        className="flex flex-wrap pl-2 gap-2 transform scale-90"
        style={{ transformOrigin: "left" }}
      >
        {props.uniqueDepartments.map((department, i) => (
          <Badge key={i} className="text-sm bg-gray-500">
            {department.name && department.name.length > 30
              ? `${department.name.substring(0, 30)}...`
              : department.name}
          </Badge>
        ))}
      </div>
    </div>
  );
};

const ConfirmActionableRemovable = (props: {
  alertOpen: { tag: RequirementTag | null; open: boolean };
  setAlertOpen: React.Dispatch<
    React.SetStateAction<{ tag: RequirementTag | null; open: boolean }>
  >;
  onSaveClick: (tag: RequirementTag | null) => void;
}) => {
  return (
    <AlertDialog
      open={props.alertOpen.open}
      onOpenChange={(open) => {
        props.setAlertOpen((prev) => ({
          ...prev,
          open: open,
        }));
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you wish to switch the requirement from Actionable?
            This will delete the associated action items.
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={() => {
              props.onSaveClick(props.alertOpen.tag);
              props.setAlertOpen((prev) => ({
                ...prev,
                open: false,
              }));
            }}
          >
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const IndividualActionItemView = (props: {
  docId: string;
  actionItemRoutePrefix: string;
  actionItem: ActionItem;
  index: number;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  existingActionItem: ActionItem | null;
  setExistingActionItem: React.Dispatch<
    React.SetStateAction<ActionItem | null>
  >;
  requirementId: string;
}) => {
  const activeActionItem = props.existingActionItem?.id === props.actionItem.id;
  const authInfo = useAuthInfo();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const onClickSave = useCallback(
    async (e?: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSaveLoading(true);
      const actionItemToSave = {
        ...props.existingActionItem,
      } as ActionItem;
      if (e) {
        actionItemToSave.text = e.target.value;
      }
      let newAction = false;
      if (actionItemToSave.id === "") {
        actionItemToSave.id = uuidv4();
        newAction = true;
      }
      const response = await saveActionItem(
        props.docId,
        props.actionItem.requirement_id,
        actionItemToSave,
        authInfo.accessToken ?? ""
      );
      if (response) {
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.actionItem.requirement_id) {
              return {
                ...requirement,
                action_items: requirement.action_items.map((item) => {
                  if (item.id === props.existingActionItem?.id) {
                    return {
                      ...actionItemToSave,
                      text_updated_by: {
                        id: authInfo.user?.userId ?? "unknown",
                        email: authInfo.user?.email ?? "unknown",
                        first_name: authInfo.user?.firstName ?? "unknown",
                        last_name: authInfo.user?.lastName ?? "unknown",
                      },
                      text_updated_at: new Date().toISOString().slice(0, -1),
                    };
                  }
                  return item;
                }),
              };
            }
            return requirement;
          });
        });
        if (newAction) {
          for (const citation of actionItemToSave.citations) {
            await addCitation(
              `gap-analysis/action-item/impacted-document/citation/${props.docId}/${props.requirementId}/${actionItemToSave.id}`,
              citation,
              authInfo.accessToken ?? ""
            );
          }
          await saveAssignees(
            `gap-analysis/action-item/assignees/${props.docId}/${props.requirementId}/${actionItemToSave.id}`,
            actionItemToSave.assignees,
            authInfo.accessToken ?? ""
          );
        }
      } else {
        toast.error("Failed to save");
      }
      setSaveLoading(false);
    },
    [props.existingActionItem]
  );

  return (
    <div
      key={props.actionItem.id}
      className="flex justify-between items-start space-x-2 pl-2"
    >
      <div className="flex-auto">
        {activeActionItem ? (
          <AutoSavingTextArea
            onSave={(e) => {
              if (props.existingActionItem?.id) {
                onClickSave(e);
              }
            }}
            textareaProps={{
              placeholder: "Create Action Item...",
              value: props.existingActionItem!.text,
              onChange: (e) => {
                props.setExistingActionItem((prev) => {
                  if (prev) {
                    return {
                      ...prev,
                      text: e.target.value,
                    };
                  }
                  return prev;
                });
              },
            }}
            updatedText={
              props.actionItem?.text_updated_at &&
              props.actionItem?.text_updated_by && (
                <div className="text-xs text-gray-500 text-center">
                  Last updated{" "}
                  <TimeAgo timestamp={props.actionItem.text_updated_at} /> by{" "}
                  {`${props.actionItem.text_updated_by.first_name}${props.actionItem.text_updated_by.last_name ? ` ${props.actionItem.text_updated_by.last_name[0]}.` : ""}`}
                </div>
              )
            }
          />
        ) : (
          <div className="flex items-start space-x-2">
            <div className="text-sm">
              {props.actionItem.text.length > 300 ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex text-sm pt-0.5">
                      <span className="mr-1">{props.index + 1}. </span>
                      {props.actionItem.text.slice(0, 300)}...
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="right" className="w-[500px]">
                    {props.actionItem.text}
                  </TooltipContent>
                </Tooltip>
              ) : (
                <div className="flex text-sm pt-0.5">
                  <span className="mr-1">{props.index + 1}. </span>
                  {props.actionItem.text}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="transform scale-75 flex items-center space-x-2 ">
        {!activeActionItem && (
          <>
            <Badge
              variant="secondary"
              className="cursor-pointer"
              onClick={() => {
                props.setExistingActionItem(props.actionItem);
              }}
            >
              Edit
            </Badge>
            <ConfirmActionItemDeletion
              docId={props.docId}
              actionItem={props.actionItem}
              setRequirements={props.setRequirements}
            >
              <Badge variant="destructive" className="cursor-pointer">
                Delete
              </Badge>
            </ConfirmActionItemDeletion>
          </>
        )}
        {activeActionItem && (
          <>
            {props.existingActionItem?.text && (
              <Badge
                variant="default"
                className="cursor-pointer"
                onClick={(_) => {
                  onClickSave();
                  props.setExistingActionItem(null);
                }}
              >
                Done
              </Badge>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const IndividualResourceActionsView = (props: {
  requirement: Requirement;
  docId: string;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  existingActionItem: ActionItem | null;
  setExistingActionItem: React.Dispatch<
    React.SetStateAction<ActionItem | null>
  >;
  actionItemRoutePrefix: string;
}) => {
  const authInfo = useAuthInfo();
  const [addActionItemLoading, setAddActionItemLoading] =
    useState<boolean>(false);

  const onClickAddActionItem = async () => {
    setAddActionItemLoading(true);
    try {
      for await (const output of generateActionItem(
        `gap-analysis/action-item/generate/${props.docId}/${props.requirement.id}`,
        authInfo.accessToken ?? null
      )) {
        const newActionItem = { ...output };
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === props.requirement.id) {
              return {
                ...requirement,
                action_items: [
                  ...requirement.action_items.filter(
                    (item) => item.id !== newActionItem.id
                  ),
                  newActionItem,
                ],
              };
            }
            return requirement;
          });
        });
        props.setExistingActionItem(newActionItem);
      }
    } catch (error: any) {
      toast.error("Failed to generate action item");
    } finally {
      setAddActionItemLoading(false);
    }
  };

  return (
    <>
      <div className="space-y-3 bg-gray-300 rounded-b-md">
        <Separator className="bg-gray-200" />
        <div className="flex items-start justify-between">
          <div className="text-base font-semibold">Action Items</div>
          <Button
            style={{ transform: "scale(0.90)", transformOrigin: "right" }}
            onClick={onClickAddActionItem}
            variant="outline"
            size="sm"
          >
            {addActionItemLoading ? (
              <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
            ) : (
              <PlusIcon className="w-4 h-4 mr-2" />
            )}
            Add
          </Button>
        </div>
        {props.requirement.action_items
          .sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))
          .map((actionItem, i) => (
            <IndividualActionItemView
              key={i}
              actionItemRoutePrefix={props.actionItemRoutePrefix}
              docId={props.docId}
              actionItem={actionItem}
              index={i}
              setRequirements={props.setRequirements}
              setExistingActionItem={props.setExistingActionItem}
              existingActionItem={props.existingActionItem}
              requirementId={props.requirement.id!}
            />
          ))}
      </div>
    </>
  );
};

const IndividualRequirementCitationView = (props: {
  citations: Citation[];
  requirementId: string;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
}) => {
  const {
    editingIndividualRequirementCitation: editing,
    setEditingIndividualRequirementCitation: setEditing,
    individualRequirementActiveCitationIndex: activeCitationIndex,
    setIndividualRequirementActiveCitationIndex: setActiveCitationIndex,
  } = useContext(RequirementContext);

  return (
    <div className="flex items-center space-x-2">
      {props.citations.map((citation, i) => (
        <Button
          key={i}
          variant={activeCitationIndex === i ? "default" : "outline"}
          size="icon"
          onClick={() => {
            setActiveCitationIndex(i);
          }}
        >
          {i + 1}
        </Button>
      ))}
      <Tooltip>
        <TooltipTrigger>
          <Button
            variant={activeCitationIndex === -1 ? "default" : "outline"}
            size="icon"
            onClick={() => {
              setActiveCitationIndex(-1);
              setEditing(false);
            }}
          >
            <PlusIcon className="w-4 h-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Add Citation</TooltipContent>
      </Tooltip>
      {activeCitationIndex !== -1 && (
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant={editing ? "default" : "outline"}
              size="icon"
              onClick={() => setEditing((prev) => !prev)}
            >
              <Pencil1Icon className="w-4 h-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Edit Citation</TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};

const requirementCanBeAutoPopulated = (requirement: Requirement) => {
  // label is null, no citations, no assignees, no action items
  return (
    requirement.id !== null &&
    requirement.id !== "NEW" &&
    requirement.tag === null &&
    requirement.impacted_documents.length === 0 &&
    requirement.reference_documents.length === 0 &&
    requirement.assignees.length === 0 &&
    requirement.action_items.length === 0
  );
};

interface IndividualResourceViewProps {
  activeResourceId: string;
  onResourceClick: (resourceId: string) => void;
  onRequirementSaveClick: (
    tag: RequirementTag | null,
    requirement: Requirement,
    citation: PdfCitation
  ) => void;
  requirement: Requirement;
  docId: string;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setExistingActionItem: React.Dispatch<
    React.SetStateAction<ActionItem | null>
  >;
  existingActionItem: ActionItem | null;
  setActiveResourceId: React.Dispatch<React.SetStateAction<string>>;
  actionItemRoutePrefix: string;
}

const IndividualResourceView = forwardRef<
  HTMLDivElement,
  IndividualResourceViewProps
>((props, ref) => {
  const authInfo = useAuthInfo();
  const { setCitations, setPageNumber } = useContext(DocViewerContext);
  const { individualRequirementActiveCitationIndex } =
    useContext(RequirementContext);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<{
    tag: RequirementTag | null;
    open: boolean;
  }>({ tag: "actionable", open: false });
  const [autoPopulateLoading, setAutoPopulateLoading] =
    useState<boolean>(false);
  const selectedResource = props.activeResourceId === props.requirement.id;
  const actionItemIds = props.requirement.action_items.map((item) => item.id);
  const uniqueDepartments = [
    ...new Map(
      props.requirement.assignees
        .concat(
          props.requirement.action_items.flatMap(
            (action_item) => action_item.assignees
          )
        )
        .filter(Boolean)
        .map((assignee) => [assignee.id, assignee])
    ).values(),
  ].sort((a, b) => a.name.localeCompare(b.name));

  const onDeleteClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (props.requirement) {
      setDeleteLoading(true);
      const response = await updateRequirements(
        props.docId,
        "delete",
        props.requirement,
        authInfo.accessToken ?? ""
      );
      if (response !== null) {
        toast.success("Successfully deleted");
        props.setRequirements((prev) => {
          return prev.filter(
            (individualRequirement) =>
              individualRequirement.id !== props.requirement.id
          );
        });
        setCitations([]);
      } else {
        toast.error("Failed to delete");
      }
      setDeleteLoading(false);
    }
  };

  const handleAutoPopulate = async () => {
    setAutoPopulateLoading(true);
    try {
      for await (const autoPopulatedRequirement of autoPopulateRequirement(
        props.docId,
        props.requirement.id!,
        authInfo.accessToken ?? ""
      )) {
        props.setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === autoPopulatedRequirement.id) {
              return autoPopulatedRequirement;
            } else {
              return requirement;
            }
          });
        });
      }
    } catch (error: any) {
      console.error("There was an error auto-populating", error);
      toast.error("Unable to auto-populate");
    }
    setAutoPopulateLoading(false);
  };

  return (
    <div className="space-y-0" ref={ref}>
      <div
        className={cn(
          "flex space-y-2",
          selectedResource
            ? "min-h-[150px] bg-gray-300 pt-5 px-5 rounded-md"
            : "h-[100px] bg-white hover:bg-gray-200 cursor-pointer p-5 rounded-md"
        )}
        onClick={() => {
          if (props.activeResourceId !== "NEW") {
            props.setEditing(false);
            props.onResourceClick(props.requirement.id ?? "");
          }
        }}
      >
        <div className={cn("flex flex-col justify-between flex-grow pb-4")}>
          <div
            className={cn(
              "text-ellipsis overflow-hidden text-sm w-[100%] space-y-6 pb-6",
              selectedResource
            )}
          >
            <RequirementDisplay requirement={props.requirement} />
          </div>
          <div className="space-y-4">
            {selectedResource && !props.editing && (
              <>
                <div className="flex items-center justify-between">
                  {props.requirement.id &&
                    props.requirement.citations.length > 0 && (
                      <IndividualRequirementCitationView
                        citations={props.requirement.citations}
                        requirementId={props.requirement.id}
                        setRequirements={props.setRequirements}
                      />
                    )}
                  <div className="flex items-center space-x-4">
                    <ConfirmActionableRemovable
                      alertOpen={alertOpen}
                      setAlertOpen={setAlertOpen}
                      onSaveClick={(tag: RequirementTag | null) => {
                        const activeCitation =
                          props.requirement.citations[
                            individualRequirementActiveCitationIndex
                          ];
                        const pdfCitation: PdfCitation = {
                          match: activeCitation.text,
                          exactMatch: true,
                          ...activeCitation,
                          id: activeCitation.id || undefined,
                          match_index: activeCitation.match_index || undefined,
                        };
                        props.onRequirementSaveClick(
                          tag,
                          props.requirement,
                          pdfCitation
                        );
                      }}
                    />
                    {(requirementCanBeAutoPopulated(props.requirement) ||
                      autoPopulateLoading) && (
                      <Button
                        variant="default"
                        className="w-[215px]"
                        onClick={handleAutoPopulate}
                      >
                        {autoPopulateLoading ? (
                          <ReloadIcon className="w-4 h-4 animate-spin mr-2" />
                        ) : (
                          <SparkleIcon className="w-4 h-4 mr-2" />
                        )}
                        Analyze
                      </Button>
                    )}
                    <Select
                      value={props.requirement.tag ?? ""}
                      onValueChange={(tag) => {
                        if (
                          props.requirement.tag === "actionable" &&
                          tag !== "actionable"
                        ) {
                          setAlertOpen({
                            tag: tag as RequirementTag,
                            open: true,
                          });
                        } else {
                          const activeCitation =
                            props.requirement.citations[
                              individualRequirementActiveCitationIndex
                            ];
                          const pdfCitation: PdfCitation = {
                            match: activeCitation.text,
                            exactMatch: true,
                            ...activeCitation,
                            id: activeCitation.id || undefined,
                            match_index:
                              activeCitation.match_index || undefined,
                          };
                          props.onRequirementSaveClick(
                            tag as RequirementTag,
                            props.requirement,
                            pdfCitation
                          );
                        }
                      }}
                    >
                      <SelectTrigger className="w-full bg-white max-w-[250px]">
                        <SelectValue placeholder="Select label..." />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.keys(tagToLabelMap).map((tag) => (
                          <SelectItem value={tag} key={tag}>
                            {tagToLabelMap[tag as RequirementTag]}
                          </SelectItem>
                        ))}
                        <Button
                          className="w-full px-2"
                          variant="secondary"
                          size="sm"
                          onClick={() => {
                            const activeCitation =
                              props.requirement.citations[
                                individualRequirementActiveCitationIndex
                              ];
                            const pdfCitation: PdfCitation = {
                              match: activeCitation.text,
                              exactMatch: true,
                              ...activeCitation,
                              id: activeCitation.id || undefined,
                              match_index:
                                activeCitation.match_index || undefined,
                            };
                            props.onRequirementSaveClick(
                              null,
                              props.requirement,
                              pdfCitation
                            );
                          }}
                        >
                          Clear
                        </Button>
                      </SelectContent>
                    </Select>
                    {!saveLoading ? (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="destructive"
                            onClick={onDeleteClick}
                            disabled={deleteLoading}
                          >
                            <TrashIcon className="w-4 h-4" />
                            {deleteLoading && (
                              <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
                            )}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Delete Requirement</TooltipContent>
                      </Tooltip>
                    ) : (
                      <Button variant="outline" disabled={true}>
                        <ReloadIcon className="w-4 h-4 animate-spin" />
                      </Button>
                    )}
                  </div>
                </div>
                <ImpactedDepartmentView
                  docId={props.docId}
                  requirement={props.requirement}
                  setRequirements={props.setRequirements}
                  actionItemIds={actionItemIds}
                  uniqueDepartments={uniqueDepartments}
                />
                {props.requirement.tag === "actionable" && (
                  <IndividualResourceActionsView
                    actionItemRoutePrefix={props.actionItemRoutePrefix}
                    requirement={props.requirement}
                    docId={props.docId}
                    setRequirements={props.setRequirements}
                    existingActionItem={props.existingActionItem}
                    setExistingActionItem={props.setExistingActionItem}
                  />
                )}
                <RequirementImpactedDocumentView
                  requirement={props.requirement}
                  setRequirements={props.setRequirements}
                  actionItemIds={actionItemIds}
                  uniqueDepartments={uniqueDepartments}
                  docId={props.docId}
                />
                <RequirementReferenceDocumentView
                  requirement={props.requirement}
                  setRequirements={props.setRequirements}
                  actionItemIds={actionItemIds}
                  uniqueDepartments={uniqueDepartments}
                  docId={props.docId}
                />
              </>
            )}
            {selectedResource &&
              props.editing &&
              props.requirement.id === "NEW" && (
                <div className="flex items-center justify-end space-x-4">
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => {
                      props.setEditing(false);
                      props.setRequirements((prev) => {
                        return prev.filter(
                          (requirement) => requirement.id !== "NEW"
                        );
                      });
                      props.setActiveResourceId("");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
});

export const RequirementListView = (props: {
  docId: string;
  actionItemRoutePrefix: string;
}) => {
  const {
    requirements,
    activeRequirement,
    setRequirements,
    setActiveRequirementId,
    activeRequirementId,
    setEditing,
    editing,
    viewType,
    individualRequirementActiveCitationIndex,
    setIndividualRequirementActiveCitationIndex,
    editingIndividualRequirementCitation,
    setEditingIndividualRequirementCitation,
  } = useContext(RequirementContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const authInfo = useAuthInfo();
  const [requirementsToDisplay, setRequirementsToDisplay] = useState<
    Requirement[]
  >([]);
  const {
    docToView,
    setDocToView,
    setPageNumber,
    pageNumber,
    setCitations,
    scrollToElement,
  } = useContext(DocViewerContext);
  const [existingActionItem, setExistingActionItem] =
    useState<ActionItem | null>(null);
  const [labelFilter, setLabelFilter] = useState<string[]>([]);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const activeRequirementRef = useRef<HTMLDivElement>(null);

  const labelFilterCounts = getFilterCounts(
    requirements.map((requirement) => requirement.tag || "None")
  );

  useEffect(() => {
    setRequirementsToDisplay(
      requirements.filter((requirement) =>
        labelFilter.length > 0
          ? (requirement.tag && labelFilter.includes(requirement.tag)) ||
            (requirement.tag === null && labelFilter.includes("None"))
          : true
      )
    );
  }, [requirements, labelFilter]);

  const onResourceClick = (resourceId: string) => {
    setActiveRequirementId(resourceId);
    setSearchParams(
      (prev) => {
        prev.set("requirementId", resourceId);
        prev.delete("actionItemId");
        prev.set("activeTab", "requirements");
        prev.set("viewType", "list");
        return prev;
      },
      {
        replace: true,
      }
    );
  };

  const onSaveClick = useCallback(
    async (
      tag: RequirementTag | null,
      requirement: Requirement,
      citation: PdfCitation
    ) => {
      setSaveLoading(true);
      const operation = activeRequirementId === "NEW" ? "add" : "update";
      const resourceToUpdate = {
        text:
          operation === "add"
            ? citation.match
            : requirement.text + " " + citation.match,
        id: activeRequirementId === "NEW" ? null : activeRequirementId,
        action_items: requirement?.action_items ?? [],
        tag: tag,
        impacted_documents: requirement.impacted_documents ?? [],
        citations: requirement.citations ?? [],
        assignees: requirement.assignees ?? [],
        reference_doc_types: requirement.reference_doc_types ?? [],
        reference_documents: requirement.reference_documents ?? [],
        updated_at: new Date().toISOString().slice(0, -1),
      } as Requirement;

      const response = await updateRequirements(
        props.docId,
        operation,
        resourceToUpdate,
        authInfo.accessToken ?? ""
      );
      if (response !== null) {
        const newCitation = {
          id: null,
          page: citation.page,
          doc_id: docToView?.docId,
          doc_name: "",
          text: citation.match,
          formatted_text: null,
          start_index: null,
          match_index: citation.match_index,
          created_at: new Date().toISOString().slice(0, -1),
          user: {
            id: authInfo.user?.userId,
            first_name: authInfo.user?.firstName,
            last_name: authInfo.user?.lastName,
            email: authInfo.user?.email,
          } as SimpleUser,
        } as Citation;
        if (operation === "add") {
          const citationResponse = await addCitation(
            `gap-analysis/requirement/citation/${docToView?.docId}/${response.id}`,
            newCitation,
            authInfo.accessToken ?? ""
          );
          if (citationResponse !== null) {
            newCitation.id = citationResponse.citation_ids[0];
            newCitation.start_index =
              citationResponse.citation_start_indices[0];
            toast.success("Successfully saved");
          } else {
            toast.error("Failed to save citation");
          }
        }
        resourceToUpdate.id = response.id;
        setRequirements((prev) => {
          if (operation === "update") {
            let newResources = prev.map((resource) => {
              if (resource.id === response.id) {
                return {
                  ...resourceToUpdate,
                  action_items:
                    resource.tag === "actionable" &&
                    resourceToUpdate.tag !== "actionable"
                      ? []
                      : resourceToUpdate.action_items,
                };
              } else {
                return resource;
              }
            });
            return newResources;
          } else {
            return prev.map((resource) => {
              if (resource.id === "NEW") {
                return {
                  ...resourceToUpdate,
                  citations: [newCitation],
                };
              } else {
                return resource;
              }
            });
          }
        });
        setActiveRequirementId(resourceToUpdate.id);
        setEditing(false);
        toast.success("Successfully saved");
      } else {
        toast.error("Failed to save");
      }
      setSaveLoading(false);
    },
    [requirements, activeRequirementId, setRequirements]
  );

  const onNewCitation = async (pdfCitation: PdfCitation) => {
    setSaveLoading(true);
    const newCitation = {
      id: null,
      page: pdfCitation.page,
      doc_id: docToView?.docId,
      doc_name: "",
      text: pdfCitation.match,
      formatted_text: null,
      start_index: null,
      match_index: pdfCitation.match_index,
      created_at: new Date().toISOString().slice(0, -1),
      user: {
        id: authInfo.user?.userId ?? "",
        first_name: authInfo.user?.firstName ?? "",
        last_name: authInfo.user?.lastName ?? "",
        email: authInfo.user?.email ?? "",
      },
    } as Citation;
    const response = await addCitation(
      `gap-analysis/requirement/citation/${docToView?.docId}/${activeRequirementId}`,
      newCitation,
      authInfo.accessToken ?? ""
    );
    if (response !== null) {
      let requirementCitationLen = 0;
      setRequirements((prev) => {
        return prev.map((requirement) => {
          if (requirement.id === activeRequirementId) {
            requirementCitationLen = requirement.citations.length;
            newCitation.id = response.citation_ids[0];
            newCitation.start_index = response.citation_start_indices[0];
            return {
              ...requirement,
              citations: [...requirement.citations, newCitation],
              text: requirement.text + " " + newCitation.text,
            };
          }
          return requirement;
        });
      });
      setIndividualRequirementActiveCitationIndex(requirementCitationLen);
      toast.success("Successfully saved citation");
    } else {
      toast.error("Failed to save citation");
    }
    setSaveLoading(false);
  };

  const onUpdateCitation = async (pdfCitation: PdfCitation) => {
    if (activeRequirement && individualRequirementActiveCitationIndex >= 0) {
      setSaveLoading(true);
      const newCitation = {
        id: activeRequirement?.citations[
          individualRequirementActiveCitationIndex
        ].id,
        page: pageNumber,
        doc_id: docToView?.docId,
        doc_name: "",
        text: pdfCitation.match,
        formatted_text: null,
        start_index: null,
        match_index: pdfCitation.match_index,
        created_at: new Date().toISOString().slice(0, -1),
        user: {
          id: authInfo.user?.userId ?? "",
          first_name: authInfo.user?.firstName ?? "",
          last_name: authInfo.user?.lastName ?? "",
          email: authInfo.user?.email ?? "",
        },
      } as Citation;
      const response = await addCitation(
        `gap-analysis/requirement/citation/${docToView?.docId}/${activeRequirementId}`,
        newCitation,
        authInfo.accessToken ?? ""
      );
      if (response !== null) {
        setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === activeRequirementId) {
              newCitation.id = response.citation_ids[0];
              newCitation.start_index = response.citation_start_indices[0];
              const newCitations = requirement.citations.map((citation) => {
                if (
                  citation.id ===
                  activeRequirement.citations[
                    individualRequirementActiveCitationIndex
                  ].id
                ) {
                  return newCitation;
                }
                return citation;
              });
              return {
                ...requirement,
                text: newCitations.map((citation) => citation.text).join(" "),
                citations: newCitations,
              };
            }
            return requirement;
          });
        });
        toast.success("Successfully updated citation");
        setEditing(false);
      } else {
        toast.error("Failed to save citation");
      }
    }
    setSaveLoading(false);
  };

  const onDeleteCitation = async () => {
    setDeleteLoading(true);
    if (activeRequirement && individualRequirementActiveCitationIndex >= 0) {
      const response = await deleteCitation(
        `gap-analysis/requirement/citation/${docToView?.docId}/${activeRequirementId}`,
        [
          activeRequirement.citations[individualRequirementActiveCitationIndex]
            .id!,
        ],
        authInfo.accessToken ?? ""
      );
      if (response === true) {
        setRequirements((prev) => {
          return prev.map((requirement) => {
            if (requirement.id === activeRequirementId) {
              const newCitations = requirement.citations.filter(
                (citation) =>
                  citation.id !==
                  activeRequirement.citations[
                    individualRequirementActiveCitationIndex
                  ].id
              );
              return {
                ...requirement,
                citations: newCitations,
                text: newCitations.map((citation) => citation.text).join(" "),
              };
            }
            return requirement;
          });
        });
        setIndividualRequirementActiveCitationIndex(0);
      } else {
        toast.error("Failed to delete citation");
      }
    }
    setDeleteLoading(false);
  };

  const filterPdfRangeCitation = () => {
    setCitations((prevState) =>
      prevState.filter((citation) => citation.id !== HIGHLIGHTED_CITATION_ID)
    );
  };
  const getActiveCitationTooltip = (
    newCitation: PdfCitation
  ): ReactNode | false => {
    if (editing) {
      return (
        <div className="w-48">
          <HighlightTooltipActionButton
            text="Add"
            onClick={async () =>
              await onSaveClick(null, activeRequirement!, newCitation)
            }
            explanationContent="Add a new requirement from the highlighted text"
          />
        </div>
      );
    } else {
      //We are looking at an IndividualRequirementCitationView
      const activeRequirement = requirementsToDisplay.find(
        (requirement) => requirement.id === activeRequirementId
      );
      if (individualRequirementActiveCitationIndex === -1) {
        //We are making a new citation
        return (
          <div className="w-48">
            <HighlightTooltipActionButton
              text="Add"
              onClick={async () => await onNewCitation(newCitation)}
              explanationContent="Add a new citation from the highlighted text"
            />
            <HighlightTooltipActionButton
              text="Cancel"
              onClick={async () => {
                setIndividualRequirementActiveCitationIndex(0);
                setEditing(false);
              }}
              explanationContent="Cancel adding a new citation"
            />
          </div>
        );
      } else if (editingIndividualRequirementCitation) {
        //Update Tooltip
        return (
          <div className="w-48">
            <HighlightTooltipActionButton
              text="Update"
              onClick={async () => await onUpdateCitation(newCitation)}
              explanationContent="Update the citation to use highlighted text"
            />
            {activeRequirement?.citations &&
              activeRequirement.citations.length > 1 && (
                <HighlightTooltipActionButton
                  text="Delete"
                  onClick={async () => await onDeleteCitation()}
                  explanationContent="Delete the citation"
                />
              )}
          </div>
        );
      }
    }
    return false;
  };

  useEffect(() => {
    if (docToView?.docId !== props.docId) {
      setDocToView({
        docId: props.docId,
      });
    }
  }, [props.docId]);

  useEffect(() => {
    if (activeRequirement) {
      if (activeRequirement.citations.length > 0) {
        setPageNumber(activeRequirement.citations[0].page);
      }
      filterPdfRangeCitation();
      setIndividualRequirementActiveCitationIndex(0);
      setEditingIndividualRequirementCitation(false);
    }
  }, [activeRequirement?.id]);

  const updateCitations = (newCitation?: PdfCitation | undefined) => {
    setCitations(() => {
      const citationsDisplay: PdfCitation[] = [];
      let newPdfCitation: PdfCitation | false = false;
      if (newCitation) {
        let citationTooltip = getActiveCitationTooltip(newCitation);
        if (citationTooltip) {
          //We have a use for the new highlight
          newPdfCitation = {
            ...newCitation,
            citationTooltip: citationTooltip,
          };
          citationsDisplay.push(newPdfCitation);
        }
      }
      for (const [
        requirementIndex,
        requirement,
      ] of requirementsToDisplay.entries()) {
        for (const [
          citationindex,
          citation,
        ] of requirement.citations.entries()) {
          const isActiveRequirement = activeRequirementId
            ? requirement.id === activeRequirementId
            : requirementIndex === 0;
          const isActiveRequirementActiveCitation =
            individualRequirementActiveCitationIndex
              ? citationindex === individualRequirementActiveCitationIndex
              : citationindex === 0;
          const isSelected =
            isActiveRequirement && isActiveRequirementActiveCitation;
          const isBeingEdited =
            isSelected && editingIndividualRequirementCitation && newCitation;

          let pdfCitation: PdfCitation = {
            id: citation.id || `cit-${citation.text.slice(0, 14)}`,
            match: citation.text,
            exactMatch: false,
            match_index: citation.match_index || undefined,
            page: citation.page,
            className: isSelected
              ? `bg-highlight-default`
              : isActiveRequirement
                ? `bg-highlight-off`
                : `bg-highlight-inactive`,
            classNameOnHover: isSelected ? "" : "bg-highlight-default",
            onClick:
              editing ||
              editingIndividualRequirementCitation ||
              individualRequirementActiveCitationIndex === -1
                ? undefined
                : () => {
                    setActiveRequirementId(requirement.id || "");
                    setIndividualRequirementActiveCitationIndex(citationindex);
                  },
          };
          if (isSelected && !isBeingEdited) {
            const tooltip = getActiveCitationTooltip(pdfCitation);
            if (tooltip) {
              pdfCitation.citationTooltip = tooltip;
            }
          }
          if (editing && requirement.id === "NEW" && newCitation) {
            //We don't want to display the default "ADD" citation
          } else {
            citationsDisplay.push(pdfCitation);
          }
        }
      }
      return citationsDisplay;
    });
  };
  useEffect(() => {
    updateCitations();
  }, [
    requirementsToDisplay,
    activeRequirementId,
    individualRequirementActiveCitationIndex,
    editingIndividualRequirementCitation,
  ]);

  useEffect(() => {
    if (viewType === "list") {
      if (activeRequirementRef.current) {
        if (activeRequirementId !== "NEW") {
          setSearchParams(
            (prev: URLSearchParams) => {
              prev.set("requirementId", activeRequirementId);
              prev.set("activeTab", "requirements");
              return prev;
            },
            {
              replace: true,
            }
          );
        } else {
          setSearchParams(
            (prev: URLSearchParams) => {
              prev.delete("requirementId");
              prev.set("activeTab", "requirements");
              return prev;
            },
            { replace: true }
          );
        }
        setIndividualRequirementActiveCitationIndex(0);
        if (activeRequirementRef.current) {
          scrollToElement(activeRequirementRef.current);
        }
      }
    }
  }, [viewType, activeRequirementRef.current]);

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel
        defaultSize={50}
        minSize={50}
        maxSize={60}
        id="resource-panel"
        order={2}
        className="space-y-4 pr-4"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="text-sm italic text-right">{`${requirementsToDisplay.length} Requirements Found`}</div>
            <SelectFilter
              title="Label"
              filterCounts={labelFilterCounts}
              activeFilter={labelFilter}
              setActiveFilter={setLabelFilter}
              nameFormatter={(name: string) =>
                name !== "None" ? tagToLabelMap[name as RequirementTag] : "None"
              }
            />
          </div>
        </div>
        <div className="w-[100%] space-y-3 overflow-y-auto h-[calc(100vh-255px)]">
          {requirementsToDisplay.sort(citationSortOrder).map((requirement) => (
            <IndividualResourceView
              key={requirement.id}
              requirement={requirement}
              docId={props.docId}
              actionItemRoutePrefix={props.actionItemRoutePrefix}
              activeResourceId={activeRequirementId}
              onRequirementSaveClick={onSaveClick}
              onResourceClick={onResourceClick}
              setRequirements={setRequirements}
              setEditing={setEditing}
              editing={editing}
              setExistingActionItem={setExistingActionItem}
              ref={
                requirement.id === activeRequirementId
                  ? activeRequirementRef
                  : undefined
              }
              setActiveResourceId={setActiveRequirementId}
              existingActionItem={existingActionItem}
            />
          ))}
        </div>
      </ResizablePanel>
      <ResizableHandle withHandle className="mx-4" />
      <ResizablePanel
        defaultSize={50}
        minSize={40}
        maxSize={50}
        id="doc-view-panel"
        order={3}
      >
        {docToView?.docId && (
          <DocViewerCitation
            docId={docToView.docId}
            className="h-[calc(100vh-295px)]"
            hideAtlasWidget={true}
            onCitationsUpdate={(pdfCitation: PdfCitation) => {
              updateCitations(pdfCitation);
            }}
          />
        )}
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};
