import PdfViewer from "./PdfViewer";
import { PdfProvider } from "./PdfContext";
import React, { ReactNode } from "react";
import { DocumentInitParameters } from "pdfjs-dist/types/src/display/api";
import { PdfCitation, CitationText } from "./PdfHighlighter/types";

type PDFDocumentViewerProps = {
  url: string;
  fileOptions?: DocumentInitParameters;
  documentLoadingContnet?: ReactNode;
  pageLoadingContent?: ReactNode;
  pageNumber?: number;
  citations?: PdfCitation[];
  onUpdateCitation?: (citation: PdfCitation) => void;
  onUpdatePageNumber?: (page: number) => void;
  onUpdateNumPages?: (pages: number) => void;
  parentModalRef?: React.RefObject<HTMLDivElement>;
};
export const PdfDocumentViewer: React.FC<PDFDocumentViewerProps> = ({
  url,
  fileOptions,
  documentLoadingContnet,
  pageLoadingContent,
  pageNumber,
  citations,
  onUpdateCitation,
  onUpdatePageNumber,
  onUpdateNumPages,
  parentModalRef,
}: PDFDocumentViewerProps) => {
  return (
    <PdfProvider
      pageNumber={pageNumber}
      citations={citations}
      onUpdateCitation={onUpdateCitation}
      onUpdatePageNumber={onUpdatePageNumber}
      onUpdateNumPages={onUpdateNumPages}
      parentModalRef={parentModalRef}
    >
      <PdfViewer
        {...{
          url,
          fileOptions,
          documentLoadingContnet,
          pageLoadingContent,
        }}
      />
    </PdfProvider>
  );
};
