import {
  DocType,
  ManagedUser,
  PaginationOutput,
  RegulatoryDoc,
  SearchDocName,
  SearchDocumentResponse,
  DataSource,
  RegulatoryDocOverview,
  Requirement,
  AuditQuestion,
  Citation,
  Audit,
  ExactMatches,
  Insight,
  Note,
  Feature,
  FileIndex,
  ApprovalEvent,
  DocHistory,
  DocMetadata,
  WorkflowApprovalStatus,
  DocDiff,
  SimpleUser,
  PolicyRepoOverview,
  RecentlyViewedDoc,
  Attestation,
  ApprovalConfig,
  Department,
  ApprovalFlow,
  PolicyRepoError,
  ActionItem,
  RegulatoryDocOverviewItem,
  PreviousSearch,
  GapAnalysis,
  AddCitationResponse,
  ModelChat,
  ChatHistoryMetadata,
  AuditResource,
  TaskStatus,
  RegulatoryDocConfiguration,
  SearchResource,
  AuditQuestionReviewDoc,
  FeedDocType,
  SearchResult,
} from "../types";
import Ajax from "./Ajax";

let baseUrl = "";
if (import.meta.env.VITE_ENV === "prod") {
  baseUrl = "https://api.diligentaide.com";
} else if (import.meta.env.VITE_ENV === "staging") {
  baseUrl = "https://api.ovalsight.com";
}

export const runSearch = async function* (
  query: string,
  numResults: number,
  docTypeIds: string[],
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<SearchDocumentResponse>({
    url: `${baseUrl}/api/v1/search/`,
    method: "POST",
    body: {
      query,
      num_results: numResults,
      doc_type_ids: docTypeIds,
    },
    accessToken,
  })) {
    if (payload.error) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const searchAdditionalResults = async function* (
  queryId: string,
  additionalResults: number,
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<SearchDocumentResponse>({
    url: `${baseUrl}/api/v1/search/load-more/${queryId}?additionalResults=${additionalResults}`,
    method: "POST",
    accessToken,
  })) {
    if (payload.error) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const getDocTypes = async (accessToken: string | null) => {
  let response = null;
  try {
    const url = `${baseUrl}/api/v1/user/doc-types`;
    response = await Ajax.req<DocType[]>({
      url,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getSearchResults = async (
  searchId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<SearchDocumentResponse>({
      url: `${baseUrl}/api/v1/search/result/${searchId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const resultClick = async (
  searchId: string,
  searchDocId: string,
  accessToken: string | null
) => {
  await Ajax.req({
    url: `${baseUrl}/api/v1/search/result-click`,
    method: "POST",
    body: {
      search_id: searchId,
      search_doc_id: searchDocId,
    },
    accessToken,
  });
};

export const getUsers = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<ManagedUser[]>({
      url: `${baseUrl}/api/v1/user/all/full`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getSimpleUsers = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<SimpleUser[]>({
      url: `${baseUrl}/api/v1/user/all/simple`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateUser = async (
  user: ManagedUser,
  accessToken: string | null
) => {
  let response = true;
  try {
    response = await Ajax.req({
      url: `${baseUrl}/api/v1/user/update`,
      method: "POST",
      body: {
        id: user.id,
        permissions: user.permissions,
        role: user.role,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const allRegulatoryDocs = async (
  limit: number,
  page: number,
  docTypes: string[],
  statuses: string[],
  relevantFilters: string[],
  sortingParams: { id: string; desc: boolean }[],
  accessToken: string | null
) => {
  let response = null;
  try {
    let url = `${baseUrl}/api/v1/regulatory-docs/all?limit=${limit}&page=${page}`;
    if (docTypes.length > 0) {
      docTypes.forEach((docType) => {
        url += `&doc_types=${docType}`;
      });
    }
    if (statuses.length > 0) {
      statuses.forEach((status) => {
        url += `&statuses=${status}`;
      });
    }
    if (relevantFilters.length > 0) {
      relevantFilters.forEach((relevantFilter) => {
        url += `&relevant=${relevantFilter}`;
      });
    }
    if (sortingParams.length > 0) {
      sortingParams.forEach((sortingParam) => {
        url += `&sorting=${sortingParam.id}__${sortingParam.desc ? "desc" : "asc"}`;
      });
    }
    response = await Ajax.req<{
      docs: RegulatoryDoc[];
      pagination_output: PaginationOutput | null;
    }>({
      url,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const createRegulatoryDocOverview = async function* (
  regulatoryDocId: string,
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<
    RegulatoryDocOverview | { error: string }
  >({
    url: `${baseUrl}/api/v1/regulatory-docs/overview/${regulatoryDocId}`,
    method: "POST",
    body: {},
    accessToken,
  })) {
    if ("error" in payload) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const createPdfUrl = (docId: string, historyId?: string) => {
  let url = `${baseUrl}/data/${docId}`;
  if (historyId) {
    url += `?history_id=${historyId}`;
  }
  return url;
};

export const viewPdf = async (
  docId: string,
  accessToken: string | null,
  historyId?: string
) => {
  let response = null;
  try {
    let url = `${baseUrl}/data/${docId}`;
    if (historyId) {
      url += `?history_id=${historyId}`;
    }
    response = await Ajax.req<{
      filename: string;
      blob: Blob;
    }>({
      url,
      method: "GET",
      accessToken,
    });
    response.filename = response.filename
      ? response.filename.substring(1, response.filename.length - 1)
      : "";
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getFilterDocTypes = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<FeedDocType[]>({
      url: `${baseUrl}/api/v1/regulatory-docs/filter-doc-types`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getRegulatoryDoc = async (
  regulatoryDocId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<RegulatoryDoc>({
      url: `${baseUrl}/api/v1/regulatory-docs/${regulatoryDocId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const searchResource = async function* (
  query: string,
  docTypeIds: string[],
  contentMatch: boolean,
  nameOnly: boolean,
  accessToken: string | null
) {
  let url = `${baseUrl}/api/v1/search/resource?query=${encodeURIComponent(
    query
  )}&content_match=${contentMatch}&name_only=${nameOnly}`;
  if (docTypeIds.length > 0) {
    docTypeIds.forEach((docTypeId) => {
      url += `&doc_types=${docTypeId}`;
    });
  }

  for await (const payload of Ajax.stream<{
    results: SearchResource[];
    query_id: string;
    error?: string;
  }>({
    url,
    method: "GET",
    accessToken,
  })) {
    if (payload.error) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const chatDoc = async function* (
  chatId: string | null,
  userMessage: string,
  resources: SearchResource[],
  page: number,
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<{
    chat_id: string;
    output_message: ModelChat;
    citations: Citation[];
    error: string | null;
  }>({
    url: `${baseUrl}/api/v1/chat/`,
    method: "POST",
    body: {
      user_message: userMessage,
      page,
      chat_id: chatId,
      resources,
    },
    accessToken,
  })) {
    if (payload.error) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const getChat = async (chatId: string, accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<{
      chat: ModelChat[];
      citations: Citation[];
      resources: SearchResource[];
    }>({
      url: `${baseUrl}/api/v1/chat/${chatId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const refreshData = async (
  docTypeId: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/data/refresh/${docTypeId}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const updateDataSources = async (
  dataSources: DataSource[],
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/user/data-sources`,
      method: "POST",
      body: {
        data_sources: dataSources,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const getDataSources = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<DataSource[]>({
      url: `${baseUrl}/api/v1/user/data-sources`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const refreshDataSource = async (
  docTypeId: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/user/refresh/${docTypeId}`,
      method: "POST",
      body: {},
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const updateRequirements = async (
  docId: string,
  operation: "add" | "update" | "delete",
  resource: Requirement,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{
      id: string;
    }>({
      url: `${baseUrl}/api/v1/gap-analysis/requirement/${docId}/${operation}`,
      method: "POST",
      body: {
        id: resource.id,
        text: resource.text,
        tag: resource.tag,
        reference_doc_types: resource.reference_doc_types,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getRequirements = async (
  docId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{
      requirements: Requirement[];
      doc_id: string;
    }>({
      url: `${baseUrl}/api/v1/gap-analysis/requirement/${docId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getRequirement = async (
  docId: string,
  requirementId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<Requirement>({
      url: `${baseUrl}/api/v1/gap-analysis/requirement/${docId}/${requirementId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getAuditQuestionAnswer = async (
  auditResourceId: string,
  questionId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{
      question: AuditQuestion;
      previous_question_id: string | null;
      next_question_id: string | null;
    }>({
      url: `${baseUrl}/api/v1/audit/answer/${auditResourceId}/${questionId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateAuditQuestion = async (
  auditResourceId: string,
  questionId: string,
  narrative: string | null,
  status: TaskStatus | null,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/audit/answer/${auditResourceId}/${questionId}`,
      method: "POST",
      body: {
        narrative,
        status,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const addCitation = async (
  urlSuffix: string,
  citation: Citation,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<AddCitationResponse>({
      url: `${baseUrl}/api/v1/${urlSuffix}`,
      method: "POST",
      body: {
        ...citation,
        id: citation.id || null,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const deleteCitation = async (
  urlSuffix: string,
  citationIds: string[],
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/${urlSuffix}/delete`,
      method: "POST",
      body: {
        citation_ids: citationIds,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const getQuestions = async (
  auditResourceId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<AuditQuestion[]>({
      url: `${baseUrl}/api/v1/audit/questions/${auditResourceId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getAudits = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<Audit[]>({
      url: `${baseUrl}/api/v1/audit/all`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getInsights = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<Insight[]>({
      url: `${baseUrl}/api/v1/insight/all`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const downloadInsight = async (
  insightId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<any[]>({
      url: `${baseUrl}/api/v1/insight/data/${insightId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getInsight = async (
  insightId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<Insight>({
      url: `${baseUrl}/api/v1/insight/${insightId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const createNarrative = async function* (
  auditResourceId: string,
  questionId: string,
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<{
    narrative: string;
    error?: string;
  }>({
    url: `${baseUrl}/api/v1/audit/create/narrative/${auditResourceId}/${questionId}`,
    method: "POST",
    body: {},
    accessToken,
  })) {
    if (payload.error) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const searchIndividualDoc = async function* (
  query: string,
  docId: string,
  exactMatch: boolean,
  accessToken: string | null
) {
  let url = `${baseUrl}/api/v1/search/individual-doc?exact_match=${exactMatch}`;
  for await (const payload of Ajax.stream<{
    pages_with_exact_match: ExactMatches | null;
    search_results: Citation[];
    query_id: string;
    error?: string;
  }>({
    url,
    method: "POST",
    body: {
      query,
      doc_id: docId,
    },
    accessToken,
  })) {
    if (payload.error) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const getAuditRelevantDocs = async (
  auditResourceId: string,
  questionId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<SearchDocName[]>({
      url: `${baseUrl}/api/v1/audit/relevant-docs/${auditResourceId}/${questionId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const createNote = async (
  urlSuffix: string,
  note: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<Note>({
      url: `${baseUrl}/api/v1/${urlSuffix}`,
      method: "POST",
      body: {
        note,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const searchResourceClick = async (
  queryId: string,
  resource: SearchResource,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/search/resource-click/${queryId}/${resource.id}/${resource.type}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const individualDocClick = async (
  queryId: string,
  resultType: "page" | "result",
  resultId: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/search/individual-doc-click/${queryId}/${resultType}/${resultId}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const downloadData = async (
  docId: string,
  accessToken: string | null | undefined
) => {
  let success = true;
  try {
    const response = await Ajax.req<{ filename: string; blob: Blob }>({
      url: `${baseUrl}/data/download/${docId}`,
      method: "GET",
      accessToken,
    });
    response.filename = response.filename
      ? response.filename.substring(1, response.filename.length - 1)
      : "";
    const link = document.createElement("a");
    link.href = URL.createObjectURL(response.blob);
    link.download = response.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.error(e);
    success = false;
  }
  return success;
};

export const noteReact = async (
  urlSuffix: string,
  noteId: string,
  reaction: string | null,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/${urlSuffix}`,
      method: "POST",
      body: {
        note_id: noteId,
        reaction,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const getOrganizationFeatures = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<Feature[]>({
      url: `${baseUrl}/api/v1/user/features`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getFileIndex = async (
  docTypeId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<FileIndex>({
      url: `${baseUrl}/data/file-index/${docTypeId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getDocOwners = async (
  docIds: string[],
  accessToken: string | null
) => {
  let response = null;
  try {
    let url = `${baseUrl}/api/v1/user/doc-owner?`;
    docIds.forEach((docId) => {
      url += `doc_ids=${docId}&`;
    });
    url = url.slice(0, -1);
    response = await Ajax.req<Department[]>({
      url,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getCurrentApprovalStatus = async (
  docId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<WorkflowApprovalStatus>({
      url: `${baseUrl}/api/v1/policy-repo/approval-status/${docId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const startApproval = async (
  docId: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/policy-repo/approval-start/${docId}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const signalApproval = async (
  docId: string,
  approval_type: "cancel" | "approve" | "reject",
  stage_id: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/policy-repo/approval-signal/${docId}`,
      method: "POST",
      body: {
        approval_type,
        stage_id,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const getDocMetadata = async (
  docId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<DocMetadata>({
      url: `${baseUrl}/data/doc-metadata/${docId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getPolicyRepoDocTypes = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<DocType[]>({
      url: `${baseUrl}/api/v1/policy-repo/doc-types`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getDocHistory = async (
  docId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<DocHistory[]>({
      url: `${baseUrl}/api/v1/policy-repo/history/${docId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getApprovalEvents = async (
  docId: string,
  flowInstanceId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<ApprovalEvent[]>({
      url: `${baseUrl}/api/v1/policy-repo/approval-events/${docId}/${flowInstanceId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getDocDiffs = async (
  docId: string,
  baseFlowInstanceId: string,
  compareFlowInstanceId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<DocDiff[]>({
      url: `${baseUrl}/api/v1/policy-repo/diff/${docId}/${baseFlowInstanceId}/${compareFlowInstanceId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getDiffView = async (
  docId: string,
  baseFlowInstanceId: string,
  compareFlowInstanceId: string,
  page: number,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{ base: string; compare: string }>({
      url: `${baseUrl}/api/v1/policy-repo/diff-view/${docId}/${baseFlowInstanceId}/${compareFlowInstanceId}/${page}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateApprovalConfig = async (
  docId: string,
  approvalConfig: ApprovalConfig,
  approvalWorkflow: ApprovalFlow | null,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{ schedule_instance_id: string | null }>({
      url: `${baseUrl}/api/v1/policy-repo/approval-config/${docId}`,
      method: "POST",
      body: {
        ...approvalConfig,
        approval_flow: approvalWorkflow,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getApprovalConfig = async (
  docId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<ApprovalConfig>({
      url: `${baseUrl}/api/v1/policy-repo/approval-config/${docId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateQuestionAssignments = async (
  auditResourceId: string,
  questionId: string,
  assignees: Department[],
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/audit/questions/${auditResourceId}/${questionId}/assignments`,
      method: "POST",
      body: { assignees: assignees },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const getPolicyRepoOverview = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<PolicyRepoOverview>({
      url: `${baseUrl}/api/v1/policy-repo/overview`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getRecentlyViewedDocs = async (
  docTypeIds: string[],
  accessToken: string | null
) => {
  let response = null;
  try {
    let url = `${baseUrl}/data/recently-viewed-docs`;
    if (docTypeIds.length > 0) {
      docTypeIds.forEach((docTypeId) => {
        url += `&doc_types=${docTypeId}`;
      });
      url = url.replace("&", "?");
    }
    response = await Ajax.req<RecentlyViewedDoc[]>({
      url,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const deleteNote = async (
  urlSuffix: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/${urlSuffix}`,
      method: "DELETE",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const userAttest = async (
  flowInstanceId: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/policy-repo/attest/${flowInstanceId}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const getFlowAttestations = async (
  flowInstanceId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<Attestation[]>({
      url: `${baseUrl}/api/v1/policy-repo/attestations/${flowInstanceId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getDepartments = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<Department[]>({
      url: `${baseUrl}/api/v1/user/departments`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getApprovalFlows = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<ApprovalFlow[]>({
      url: `${baseUrl}/api/v1/policy-repo/approval-flows`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const checkPolicyRepoDocForErrors = async (
  docId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<PolicyRepoError[]>({
      url: `${baseUrl}/api/v1/policy-repo/check-doc/${docId}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getRelevantDocs = async (
  docId: string,
  requirementId: string,
  docTypeIds: string[],
  citationType: "impacted-document" | "reference-document",
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<SearchDocName[]>({
      url: `${baseUrl}/api/v1/gap-analysis/requirement/${citationType}/relevant-docs/${docId}/${requirementId}`,
      method: "POST",
      body: {
        doc_type_ids: docTypeIds,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getInternalDocs = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<{ id: string; name: string }[]>({
      url: `${baseUrl}/data/internal-docs`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getDocDepartmentAssignments = async (
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{ doc_id: string; department_id: string }[]>({
      url: `${baseUrl}/data/doc-department-assignments`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const departmentUpdate = async (
  departments: Department[],
  doc_department_assignments: { doc_id: string; department_id: string }[],
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/data/department-update`,
      method: "POST",
      body: { departments, doc_department_assignments },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const saveActionItem = async (
  docId: string,
  requirementId: string,
  actionItem: ActionItem,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/gap-analysis/action-item/${docId}/${requirementId}`,
      method: "POST",
      body: {
        id: actionItem.id,
        text: actionItem.text,
        status: actionItem.status,
        text_updated_at: "",
        text_updated_by: actionItem.text_updated_by,
        status_updated_at: "",
        status_updated_by: actionItem.status_updated_by,
        created_at: "",
        requirement_id: requirementId,
        updated_at: "",
        reference_doc_types: actionItem.reference_doc_types,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const saveAssignees = async (
  urlSuffix: string,
  assignees: Department[],
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/${urlSuffix}`,
      method: "POST",
      body: {
        assignees,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const updateOverviewItems = async (
  regulatoryDocId: string,
  itemsToUpsert: RegulatoryDocOverviewItem[],
  itemsToDelete: RegulatoryDocOverviewItem[],
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/regulatory-docs/overview-items/${regulatoryDocId}`,
      method: "POST",
      body: { items_to_upsert: itemsToUpsert, items_to_delete: itemsToDelete },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const generateCitations = async function* (
  urlSuffix: string,
  docTypeIds: string[],
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<{
    citations: Citation[];
    departments: Department[];
  }>({
    url: `${baseUrl}/api/v1/${urlSuffix}`,
    method: "POST",
    body: {
      doc_type_ids: docTypeIds,
    },
    accessToken,
  })) {
    yield payload;
  }
};

export const getPreviousSearches = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<PreviousSearch[]>({
      url: `${baseUrl}/api/v1/search/previous-searches`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const deleteActionItem = async (
  docId: string,
  requirementId: string,
  actionItemId: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/gap-analysis/action-item/${docId}/${requirementId}/${actionItemId}`,
      method: "DELETE",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

interface ExportResponse {
  filename: string;
  blob: Blob;
}

const handleExport = (response: ExportResponse) => {
  response.filename = response.filename
    ? response.filename.substring(1, response.filename.length - 1)
    : "";
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(response.blob);
  link.download = response.filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportRequirements = async (
  docId: string,
  includePolicyText: boolean,
  accessToken: string | null
) => {
  let success = true;
  try {
    const response = await Ajax.req<ExportResponse>({
      url: `${baseUrl}/api/v1/gap-analysis/export/${docId}?includePolicyText=${includePolicyText}`,
      method: "GET",
      headers: {
        Accept: "text/csv; charset=utf-8",
      },
      accessToken,
    });
    handleExport(response);
  } catch (e) {
    console.error(e);
    success = false;
  }
  return success;
};

export const exportAuditQuestions = async (
  auditResourceId: string,
  includePolicyText: boolean,
  accessToken: string | null
) => {
  let success = true;
  try {
    const response = await Ajax.req<ExportResponse>({
      url: `${baseUrl}/api/v1/audit/export/${auditResourceId}?includePolicyText=${includePolicyText}`,
      method: "GET",
      accessToken,
    });
    handleExport(response);
  } catch (e) {
    console.error(e);
    success = false;
  }
  return success;
};

export const getGapAnalyses = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<GapAnalysis[]>({
      url: `${baseUrl}/api/v1/gap-analysis/all`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getGapAnalysis = async (
  gapAnalysisId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<GapAnalysis>({
      url: `${baseUrl}/api/v1/gap-analysis/${gapAnalysisId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const createGapAnalysis = async (
  name: string,
  doc_id: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{ id: string }>({
      url: `${baseUrl}/api/v1/gap-analysis/create`,
      method: "POST",
      body: { name, doc_id },
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const generateActionItem = async function* (
  urlSuffix: string,
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<ActionItem | { error: string }>({
    url: `${baseUrl}/api/v1/${urlSuffix}`,
    method: "POST",
    body: {},
    accessToken,
  })) {
    if ("error" in payload) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const generateRequirement = async (
  docId: string,
  pageOfInterest: number,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<Requirement[]>({
      url: `${baseUrl}/api/v1/gap-analysis/requirement/generate/${docId}`,
      method: "POST",
      body: {
        page_of_interest: pageOfInterest,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getCitation = async (
  citationId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<Citation>({
      url: `${baseUrl}/data/citation/${citationId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getChatHistory = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<ChatHistoryMetadata[]>({
      url: `${baseUrl}/api/v1/chat/history`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateRequirementReferenceDocTypes = async (
  docId: string,
  requirementId: string,
  docTypeIds: string[],
  accessToken: string | null
) => {
  let response = true;
  try {
    Ajax.req({
      url: `${baseUrl}/api/v1/gap-analysis/requirement/reference-doc-types/${docId}/${requirementId}`,
      method: "POST",
      body: {
        doc_type_ids: docTypeIds,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const updateAuditQuestionDocTypesToSearch = async (
  auditResourceId: string,
  questionId: string,
  docTypeIds: string[],
  accessToken: string | null
) => {
  let response = true;
  try {
    Ajax.req({
      url: `${baseUrl}/api/v1/audit/doc-types-to-search/${auditResourceId}/${questionId}`,
      method: "POST",
      body: {
        doc_type_ids: docTypeIds,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const updateActionItemReferenceDocTypes = async (
  docId: string,
  requirementId: string,
  actionItemId: string,
  docTypeIds: string[],
  accessToken: string | null
) => {
  let response = true;
  try {
    Ajax.req({
      url: `${baseUrl}/api/v1/gap-analysis/action-item/reference-doc-types/${docId}/${requirementId}/${actionItemId}`,
      method: "POST",
      body: {
        doc_type_ids: docTypeIds,
      },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const getDashboardData = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<{
      data: Record<string, any>[];
      date: string;
    }>({
      url: `${baseUrl}/data/dashboard`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getCrosswalkData = async (accessToken: string | null) => {
  let response = null;
  try {
    response = await Ajax.req<Record<string, any>[]>({
      url: `${baseUrl}/data/crosswalk`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getCrosswalkReferences = async (accessToken: string | null) => {
  let response = null;
  try {
    const rawResponse = await Ajax.req<SearchResult[]>({
      url: `${baseUrl}/data/crosswalk_references`,
      method: "GET",
      accessToken,
    });
    response = rawResponse?.map((item) => ({
      ...item,
      page: typeof item.page === "string" ? parseInt(item.page, 10) : item.page,
    }));
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const updateRegulatoryDocConfiguration = async (
  regulatoryDocId: string,
  configuration: RegulatoryDocConfiguration,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/regulatory-docs/configuration/${regulatoryDocId}`,
      method: "POST",
      body: configuration,
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const autoPopulateRequirement = async function* (
  docId: string,
  requirementId: string,
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<Requirement | { error: string }>({
    url: `${baseUrl}/api/v1/gap-analysis/requirement/auto-populate/${docId}/${requirementId}`,
    method: "POST",
    accessToken,
  })) {
    if ("error" in payload) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const getAuditResources = async (
  auditId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<AuditResource[]>({
      url: `${baseUrl}/api/v1/audit/resources/${auditId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getAuditResource = async (
  auditResourceId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<AuditResource>({
      url: `${baseUrl}/api/v1/audit/resource/${auditResourceId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const uploadItems = async (
  formData: FormData,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<{ resources: SearchResource[] }>({
      url: `${baseUrl}/data/upload`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateAuditResourceStatus = async (
  auditResourceId: string,
  status: TaskStatus,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/audit/resource-status/${auditResourceId}/${status}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const exportSearchResults = async (
  searchId: string,
  includeReferenceText: boolean,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<ExportResponse>({
      url: `${baseUrl}/api/v1/search/export/${searchId}?includeReferenceText=${includeReferenceText}`,
      method: "GET",
      headers: {
        Accept: "text/csv; charset=utf-8",
      },
      accessToken,
    });
    handleExport(response);
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const updateAuditResourceDocs = async (
  auditResourceId: string,
  docIds: string[],
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/audit/update-resource-docs/${auditResourceId}`,
      method: "POST",
      body: { doc_ids: docIds },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const auditReviewLoadDocs = async (
  auditResourceId: string,
  questionId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<AuditQuestionReviewDoc[]>({
      url: `${baseUrl}/api/v1/audit/review/load-docs/${auditResourceId}/${questionId}`,
      method: "POST",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const getAuditReviewDocs = async (
  auditResourceId: string,
  questionId: string,
  accessToken: string | null
) => {
  let response = null;
  try {
    response = await Ajax.req<AuditQuestionReviewDoc[]>({
      url: `${baseUrl}/api/v1/audit/review/docs/${auditResourceId}/${questionId}`,
      method: "GET",
      accessToken,
    });
  } catch (error) {
    console.error(error);
  }
  return response;
};

export const generateAuditReview = async function* (
  auditResourceId: string,
  questionId: string,
  accessToken: string | null
) {
  for await (const payload of Ajax.stream<{
    review_summary: string;
    review_docs: AuditQuestionReviewDoc[];
    error?: string;
  }>({
    url: `${baseUrl}/api/v1/audit/review/generate/${auditResourceId}/${questionId}`,
    method: "POST",
    accessToken,
  })) {
    if ("error" in payload) {
      throw new Error(payload.error);
    }
    yield payload;
  }
};

export const updateAuditReviewStatus = async (
  auditResourceId: string,
  questionId: string,
  auditQuestionReviewDocId: string,
  reviewStatus: string,
  accessToken: string | null
) => {
  let response = true;
  try {
    await Ajax.req({
      url: `${baseUrl}/api/v1/audit/review/status/${auditResourceId}/${questionId}/${auditQuestionReviewDocId}`,
      method: "POST",
      body: { review_status: reviewStatus },
      accessToken,
    });
  } catch (error) {
    console.error(error);
    response = false;
  }
  return response;
};

export const exportDashboardOverview = async (accessToken: string | null) => {
  let success = true;
  try {
    const response = await Ajax.req<ExportResponse>({
      url: `${baseUrl}/data/dashboard/overview`,
      method: "GET",
      accessToken,
    });
    handleExport(response);
  } catch (e) {
    console.error(e);
    success = false;
  }
  return success;
};
