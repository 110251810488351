import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../shadcn/components/breadcrumb";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../shadcn/components/tooltip";
import { Button } from "../shadcn/components/button";
import { useEffect } from "react";
import { useRef } from "react";
import { toast } from "sonner";

export const AnswerNav = (props: {
  previousQuestionId: string | null;
  nextQuestionId: string | null;
  auditId: string;
  auditResourceId: string;
  questionId: string;
  review: boolean;
}) => {
  return (
    <div className="flex items-center justify-center space-x-4">
      <Tooltip>
        <TooltipTrigger>
          <a
            href={`/audit/${props.auditId}/${props.auditResourceId}/question/${
              props.previousQuestionId
            }${props.review ? "/review" : ""}`}
          >
            <Button
              size="sm"
              variant="outline"
              disabled={!props.previousQuestionId}
            >
              <ChevronLeftIcon className="w-4 h-4 text-gray-500" />
              <span className="text-sm text-gray-500 ml-2">Previous</span>
            </Button>
          </a>
        </TooltipTrigger>
        <TooltipContent>Previous Question</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <a
            href={`/audit/${props.auditId}/${props.auditResourceId}/question/${
              props.nextQuestionId
            }${props.review ? "/review" : ""}`}
          >
            <Button
              size="sm"
              variant="outline"
              disabled={!props.nextQuestionId}
            >
              <span className="text-sm text-gray-500 mr-2">Next</span>
              <ChevronRightIcon className="w-4 h-4 text-gray-500" />
            </Button>
          </a>
        </TooltipTrigger>
        <TooltipContent>Next Question</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <a
            href={`/audit/${props.auditId}/${props.auditResourceId}/question/${
              props.questionId
            }${props.review ? "" : "/review"}`}
          >
            <Button size="sm" variant="default">
              <span className="text-sm">
                {props.review ? "Submitted Documents" : "Review Documents"}
              </span>
            </Button>
          </a>
        </TooltipTrigger>
        <TooltipContent>
          {props.review ? "Submitted Documents" : "Review Documents"}
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

export const BreadcrumbNav = (props: {
  auditId: string;
  auditResourceId: string;
  review: boolean;
}) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink href="/audit">Audits</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink href={`/audit/${props.auditId}`}>
            Audit Resources
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            href={`/audit/${props.auditId}/questionnaire/${props.auditResourceId}`}
          >
            Questions
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{props.review ? "Review" : "Answer"}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export const QuestionToast = (props: {
  questionText: string;
  children: React.ReactNode;
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const toastRef = useRef<string | number>("");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            // Element is not visible - show toast
            toastRef.current = toast.info(
              <Tooltip>
                <TooltipTrigger>Hover to View Question</TooltipTrigger>
                <TooltipContent className="max-w-[600px]">
                  {props.questionText}
                </TooltipContent>
              </Tooltip>,
              {
                duration: Infinity, // Keep toast visible until div is back in view
                position: "top-right",
              }
            );
          } else {
            // Element is visible - dismiss toast
            if (toastRef.current) {
              toast.dismiss(toastRef.current);
              toastRef.current = "";
            }
          }
        });
      },
      {
        threshold: 0, // Trigger when element is completely invisible
        rootMargin: "0px",
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.disconnect();
      if (toastRef.current) {
        toast.dismiss(toastRef.current);
      }
    };
  }, [props.questionText]);

  return <div ref={targetRef}>{props.children}</div>;
};
