import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchResult } from "../../types";
import { DocViewerCitation } from "../../components/DocViewer";
import { BaseHighlightTooltip } from "../../components/HighlightTooltipActionButton";
import { PdfCitation } from "../../components/PdfViewer/PdfHighlighter/types";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { useModalContext } from "../../contexts/ActiveModalContext";
import { processPdfString } from "../../utils/format";
import { Sheet, SheetContent, SheetTitle } from "../../shadcn/components/sheet";
import { Button } from "../../shadcn/components/button";
import { SparkleIcon } from "lucide-react";
import { HIGHLIGHTED_CITATION_ID } from "../../components/PdfViewer";

export const processRelevantQuote = (
  text: string,
  startIndex: number,
  endIndex: number
) => {
  const wordCount = 5;
  const textFmt = text.replace(//g, "•");
  const words = textFmt.split(/\s+/);

  const startWordIndex = textFmt.slice(0, startIndex).split(/\s+/).length - 1;
  const endWordIndex = textFmt.slice(0, endIndex).split(/\s+/).length;

  const startRange = Math.max(startWordIndex - wordCount, 0);
  const endRange = Math.min(endWordIndex + wordCount, words.length);

  const quote = words.slice(startWordIndex, endWordIndex).join(" ");
  const beforeQuote = words.slice(startRange, startWordIndex).join(" ");
  const afterQuote = words.slice(endWordIndex, endRange).join(" ");

  return {
    quote,
    beforeQuote,
    afterQuote,
  };
};

export const DocView = (props: {
  searchResult: SearchResult;
  setActiveSearchDocId: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const navigate = useNavigate();
  const sheetRef = useRef<HTMLDivElement>(null);
  const { setCitations } = useContext(DocViewerContext);
  const [open, setOpen] = useState(false);
  const { setActiveModalRef } = useModalContext();
  setActiveModalRef(sheetRef);
  useEffect(() => {
    setOpen(true);
  }, [props.searchResult]);

  const docChatUrl = useMemo(() => {
    const maxUrlLength = 2048;
    let url = `/doc-chat?docId=${props.searchResult.doc_id}&page=${props.searchResult.page}`;
    if (props.searchResult.relevant_quote) {
      url += `&text=`;
      const availableLength = maxUrlLength - url.length;
      const substring = props.searchResult.text.substring(
        props.searchResult.relevant_quote.start_index,
        Math.min(
          props.searchResult.relevant_quote.start_index + availableLength,
          props.searchResult.relevant_quote.end_index
        )
      );
      const processedText = processPdfString(substring);
      let encodedText = encodeURIComponent(processedText);
      if (encodedText.length > availableLength) {
        encodedText = encodedText.substring(0, availableLength);
      }
      url += encodedText;
    }
    return url;
  }, [props.searchResult.search_doc_id]);

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          props.setActiveSearchDocId(null);
        }
        setOpen(open);
      }}
    >
      <SheetContent ref={sheetRef} style={{ maxWidth: "50vw" }}>
        <SheetTitle hidden={true}>{props.searchResult.doc_name}</SheetTitle>
        <DocViewerCitation
          docId={props.searchResult.doc_id}
          className="h-[calc(100vh-130px)]"
          hideAtlasWidget={true}
          onCitationsUpdate={(pdfCitation: PdfCitation) => {
            pdfCitation.citationTooltip = (
              <BaseHighlightTooltip
                citationText={pdfCitation.match}
                citationId={pdfCitation.id ?? HIGHLIGHTED_CITATION_ID}
              />
            );

            setCitations((prev) => [
              ...prev.filter((c) => c.id !== pdfCitation.id),
              pdfCitation,
            ]);
          }}
        >
          <Button onClick={() => navigate(docChatUrl)} variant="default">
            <SparkleIcon className="h-4 w-4 mr-2" /> Chat
          </Button>
        </DocViewerCitation>
      </SheetContent>
    </Sheet>
  );
};
