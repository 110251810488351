import { v4 as uuidv4 } from "uuid";
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../shadcn/components/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/components/select";
import {
  ActionItem,
  Department,
  Requirement,
  SearchDocName,
} from "../../types";
import { RequirementDisplay } from "./GapDocDisplayUtils";
import { MultiSelectControl } from "../../components/MultiSelectControl";
import { UserContext } from "../../contexts/UserContext";
import { Textarea } from "../../shadcn/components/textarea";
import { Button } from "../../shadcn/components/button";
import { CopyIcon, ReloadIcon } from "@radix-ui/react-icons";
import { useAuthInfo } from "@propelauth/react";
import {
  addCitation,
  deleteActionItem,
  getDocOwners,
  saveActionItem,
  saveAssignees,
} from "../../utils/apiCalls";
import { toast } from "sonner";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../shadcn/components/resizable";
import { DocViewerCitation } from "../../components/DocViewer";
import { TimeAgo } from "../../utils/format";
import { Badge } from "../../shadcn/components/badge";
import { Separator } from "../../shadcn/components/separator";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../shadcn/components/alert-dialog";
import { toggleAtlasWidget } from "../../utils/cookies";
import { ActionItemFullDocumentsView } from "./ActionItemDocumentView";
import { AutoSavingTextArea } from "../AutoSavingTextArea";
import { PdfCitation } from "../PdfViewer/PdfHighlighter/types";

export const ConfirmActionItemDeletion = (props: {
  docId: string;
  actionItem: ActionItem;
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  children: React.ReactNode;
}) => {
  const authInfo = useAuthInfo();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const onDeleteClick = async () => {
    const response = await deleteActionItem(
      props.docId,
      props.actionItem.requirement_id,
      props.actionItem.id,
      authInfo.accessToken ?? ""
    );
    if (response) {
      props.setRequirements((prev) => {
        return prev.map((requirement) => {
          if (requirement.id === props.actionItem.requirement_id) {
            return {
              ...requirement,
              action_items: requirement.action_items.filter(
                (item) => item.id !== props.actionItem.id
              ),
            };
          }
          return requirement;
        });
      });
      setAlertOpen(false);
    } else {
      toast.error("Failed to delete");
    }
  };

  return (
    <AlertDialog open={alertOpen} onOpenChange={setAlertOpen}>
      <AlertDialogTrigger asChild>{props.children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you wish to delete the action item?
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button variant="destructive" onClick={onDeleteClick}>
            Delete{" "}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const RequirementSelection = (props: {
  activeRequirementId?: string;
  requirements: Requirement[];
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
}) => {
  return (
    <div className="space-y-2">
      <div className="font-semibold text-lg">Requirement</div>
      <Select
        value={props.activeRequirementId ?? undefined}
        onValueChange={(requirementId) => {
          props.setActionItem((prev) => {
            if (prev) {
              return { ...prev, requirement_id: requirementId };
            }
            return prev;
          });
        }}
      >
        <SelectTrigger className="text-left text-sm max-w-[800px] h-[120px] overflow-hidden bg-white">
          <SelectValue placeholder="Select Requirement" />
        </SelectTrigger>
        <SelectContent>
          <div className="max-h-[300px] max-w-[800px] overflow-y-scroll">
            {props.requirements.map((requirement) => (
              <SelectItem
                value={requirement.id!}
                key={requirement.id}
                className="hover:bg-gray-100 cursor-pointer"
              >
                <div className="h-[120px] overflow-hidden text-ellipsis pt-2">
                  <RequirementDisplay requirement={requirement} />
                </div>
              </SelectItem>
            ))}
          </div>
        </SelectContent>
      </Select>
    </div>
  );
};

export const ActionItemNote = (props: {
  actionItem: ActionItem | null;
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  updateActiveItem?: (text: string) => Promise<void>;
}) => {
  return (
    <div className="space-y-2">
      <div className="flex items-start justify-between">
        <div className="font-semibold text-lg">Action Item</div>
        <Button
          variant="outline"
          onClick={() => {
            navigator.clipboard.writeText(props.actionItem?.text ?? "");
            toast.success("Copied to clipboard");
          }}
          disabled={!props.actionItem?.text}
        >
          <CopyIcon className="w-4 h-4 mr-2" />
          <span className="text-sm">Copy</span>
        </Button>
      </div>
      <AutoSavingTextArea
        onSave={(e) => {
          if (props.updateActiveItem) {
            props.updateActiveItem(e.target.value);
          }
        }}
        textareaProps={{
          tabIndex: 1,
          rows: 5,
          placeholder: `Enter action item...`,
          onChange: (e) => {
            props.setActionItem((prev) => {
              if (prev) {
                return { ...prev, text: e.target.value };
              }
              return prev;
            });
          },
          value: props.actionItem?.text ?? "",
        }}
        updatedText={
          props.actionItem?.text_updated_at &&
          props.actionItem?.text_updated_by && (
            <div className="text-xs text-gray-500 text-center">
              Last updated{" "}
              <TimeAgo timestamp={props.actionItem.text_updated_at} /> by{" "}
              {`${props.actionItem.text_updated_by.first_name}${props.actionItem.text_updated_by.last_name ? ` ${props.actionItem.text_updated_by.last_name[0]}.` : ""}`}
            </div>
          )
        }
      />
    </div>
  );
};

export const AssigneeSelection = (props: {
  activeAssignees: Department[];
  setActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  parentPortalRef?: MutableRefObject<any>;
}) => {
  const { departments } = useContext(UserContext);
  return (
    <div className="flex flex-col">
      <div className="flex items-start justify-between">
        <div className="font-semibold text-lg">Impacted Departments</div>
        <div>
          <MultiSelectControl
            title="Departments"
            items={departments}
            selectedItems={props.activeAssignees ?? []}
            selectItem={(item, isSelected) => {
              if (isSelected) {
                props.setActionItem((prev) => {
                  if (prev) {
                    return {
                      ...prev,
                      assignees: [...prev.assignees, item],
                    };
                  }
                  return prev;
                });
              } else {
                props.setActionItem((prev) => {
                  if (prev) {
                    return {
                      ...prev,
                      assignees: prev.assignees.filter(
                        (department) => department.id !== item.id
                      ),
                    };
                  }
                  return prev;
                });
              }
            }}
            clearSelectedItems={() => {
              props.setActionItem((prev) => {
                if (prev) {
                  return { ...prev, assignees: [] };
                }
                return prev;
              });
            }}
            modal={true}
            container={props.parentPortalRef}
          />
        </div>
      </div>
      <div className="flex flex-wrap pt-2 pl-2 gap-2">
        {props.activeAssignees
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((assignee) => (
            <Badge key={assignee.id} className="text-sm bg-gray-400">
              {assignee.name}
            </Badge>
          ))}
      </div>
    </div>
  );
};

export const ActionItemDialog = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  requirements: Requirement[];
  setRequirements: React.Dispatch<React.SetStateAction<Requirement[]>>;
  existingActionItem: ActionItem | null;
  allowedDocTypeIds: string[];
  hideAtlasWidget?: boolean;
}) => {
  const authInfo = useAuthInfo();
  const [doc, setDoc] = useState<SearchDocName | null>(null);
  const [activeCitationId, setActiveCitationId] = useState<string | null>(null);
  const [actionItem, setActionItem] = useState<ActionItem | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [highlightedCitation, setHighlightedCitation] =
    useState<PdfCitation | null>(null);
  const activeRequirement = props.requirements.find(
    (requirement) => requirement.id === actionItem?.requirement_id
  );
  const dialogContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (props.existingActionItem) {
      setActionItem(props.existingActionItem);
    }
  }, [props.existingActionItem]);

  const onSave = async () => {
    if (activeRequirement?.id && actionItem) {
      const newActionItem = actionItem.id === "";
      if (newActionItem) {
        actionItem.id = uuidv4();
      }
      setSaveLoading(true);
      const response = await saveActionItem(
        props.docId,
        activeRequirement.id,
        actionItem,
        authInfo.accessToken ?? null
      );
      if (response) {
        // add citations
        for (const citation of actionItem.citations) {
          const response = await addCitation(
            `gap-analysis/action-item/impacted-document/citation/${props.docId}/${activeRequirement.id}/${actionItem.id}`,
            citation,
            authInfo.accessToken ?? null
          );
          if (response !== null) {
            citation.id = response.citation_ids[0];
          } else {
            toast.error("Unable to save impacted document");
            setSaveLoading(false);
            return;
          }
        }

        for (const referenceDoc of actionItem.reference_documents) {
          const response = await addCitation(
            `gap-analysis/action-item/reference-document/citation/${props.docId}/${activeRequirement.id}/${actionItem.id}`,
            referenceDoc,
            authInfo.accessToken ?? null
          );
          if (response !== null) {
            referenceDoc.id = response.citation_ids[0];
          } else {
            toast.error("Unable to save reference doc");
            setSaveLoading(false);
            return;
          }
        }

        // add assignees
        const response = await saveAssignees(
          `gap-analysis/action-item/assignees/${props.docId}/${activeRequirement.id}/${actionItem.id}`,
          actionItem.assignees,
          authInfo.accessToken ?? null
        );
        if (response === false) {
          toast.error("Unable to save assignees");
        } else {
          setActionItem((prev) => {
            if (prev) {
              return {
                ...prev,
                citations: actionItem.citations,
                id: actionItem.id,
              };
            }
            return prev;
          });
          props.setRequirements((prev) => {
            return prev.map((r) => {
              if (r.id === actionItem.requirement_id) {
                return {
                  ...r,
                  action_items: newActionItem
                    ? [...r.action_items, actionItem]
                    : r.action_items.map((existingActionItem) => {
                        if (existingActionItem.id === actionItem.id) {
                          return {
                            ...actionItem,
                            citations: actionItem.citations,
                            reference_documents: actionItem.reference_documents,
                            id: actionItem.id,
                          };
                        }
                        return existingActionItem;
                      }),
                };
              }
              return r;
            });
          });
        }
        props.setOpen(false);
      } else {
        toast.error("Unable to save action item");
      }
    } else {
      toast.error("Unable to save action item");
    }
    setSaveLoading(false);
  };

  useEffect(() => {
    if (props.hideAtlasWidget) {
      toggleAtlasWidget(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    if (activeRequirement && actionItem?.id === "") {
      let assignees: Department[] = [];
      getDocOwners(
        activeRequirement.impacted_documents.map(
          (citation) => citation.doc_id!
        ),
        authInfo.accessToken ?? null
      ).then((response) => {
        if (response !== null) {
          assignees = response;
        } else {
          toast.error("Unable to get doc owners");
        }
        setActionItem((prev) => {
          if (prev) {
            return {
              ...prev,
              citations: activeRequirement.impacted_documents,
              assignees: assignees,
            };
          }
          return prev;
        });
      });
    }
  }, [activeRequirement]);

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent className="max-w-[90%] h-[90%]" ref={dialogContainerRef}>
        <DialogHeader>
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <DialogTitle>
                <div className="space-x-2 flex items-center text-xl">
                  <div>New Action Item</div>
                </div>
              </DialogTitle>
              <DialogDescription>
                Assign an action item to a department and cite documents that
                need to be updated.
              </DialogDescription>
            </div>
            <Button
              variant="default"
              className="w-[120px] mr-12"
              onClick={onSave}
            >
              Save
              {saveLoading && <ReloadIcon className="ml-2 animate-spin" />}
            </Button>
          </div>
        </DialogHeader>
        <Separator />
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel
            defaultSize={60}
            minSize={40}
            maxSize={60}
            id="resource-panel"
            order={2}
          >
            <div className="space-y-6 px-2 pt-2 h-[85%] overflow-y-scroll">
              <RequirementSelection
                activeRequirementId={actionItem?.requirement_id}
                requirements={props.requirements}
                setActionItem={setActionItem}
              />
              {activeRequirement && (
                <>
                  <ActionItemNote
                    actionItem={actionItem}
                    setActionItem={setActionItem}
                  />
                  <ActionItemFullDocumentsView
                    docId={props.docId}
                    doc={doc}
                    requirement={activeRequirement}
                    actionItemId={actionItem?.id}
                    actionItem={actionItem}
                    setActionItem={setActionItem}
                    activeCitationId={activeCitationId}
                    setActiveCitationId={setActiveCitationId}
                    setDoc={setDoc}
                    hideAtlasWidget={false}
                    highlightedCitation={highlightedCitation}
                    setHighlightedCitation={setHighlightedCitation}
                  />
                  <AssigneeSelection
                    activeAssignees={actionItem?.assignees ?? []}
                    setActionItem={setActionItem}
                    parentPortalRef={dialogContainerRef}
                  />
                </>
              )}
            </div>
          </ResizablePanel>
          {actionItem && actionItem.citations.length > 0 && (
            <ResizableHandle withHandle className="mx-4" />
          )}
          <ResizablePanel
            defaultSize={40}
            minSize={40}
            maxSize={60}
            id="doc-view-panel"
            order={3}
          >
            {doc && (
              <DocViewerCitation
                docId={doc.doc_id}
                className="h-[calc(100vh-295px)]"
                hideAtlasWidget={props.hideAtlasWidget}
                onCitationsUpdate={(citations) => {
                  setHighlightedCitation(citations);
                }}
              />
            )}
            {(!actionItem || actionItem.citations.length === 0) && (
              <div className="flex justify-center items-center h-full">
                <div className="text-center text-gray-500">No citations</div>
              </div>
            )}
          </ResizablePanel>
        </ResizablePanelGroup>
      </DialogContent>
    </Dialog>
  );
};
