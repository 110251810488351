import { useContext, useEffect, useRef, useState } from "react";
import {
  Citation,
  Department,
  SearchDocName,
  SearchResource,
} from "../../types";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../shadcn/components/dialog";
import { Button } from "../../shadcn/components/button";
import { SearchResourceBar } from "../Resource/SearchResource";
import { DocViewerCitation } from "../DocViewer";
import { ReloadIcon } from "@radix-ui/react-icons";
import { DocViewerContext } from "../../contexts/DocViewerContext";
import { useAuthInfo } from "@propelauth/react";
import { toggleAtlasWidget } from "../../utils/cookies";
import { useModalContext } from "../../contexts/ActiveModalContext";

export const NewCitation = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  saveClick: (
    citation: Citation
  ) => Promise<{ citation_ids: string[]; departments?: Department[] } | null>;
  relevantDocs: SearchDocName[];
  allowedDocTypeIds: string[];
  headerChildren?: React.ReactNode;
  question?: string;
  successCallback?: (citation: Citation, departments?: Department[]) => void;
  onCancel?: () => void;
  allowedDocId?: string;
  hideAtlasWidget?: boolean;
}) => {
  const {
    pageNumber,
    setPageNumber,
    citations,
    setCitations,
    docToView,
    setDocToView,
  } = useContext(DocViewerContext);
  const authInfo = useAuthInfo();
  const [searchDoc, setSearchDoc] = useState<SearchResource | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const activeModalRef = useRef<HTMLDivElement>(null);
  const { setActiveModalRef } = useModalContext();
  useEffect(() => {
    if (props.open) {
      setCitations([]);
      setDocToView(null);
    }
  }, [props.open]);

  useEffect(() => {
    if (searchDoc?.id && docToView?.docId !== searchDoc.id) {
      setDocToView({
        docId: searchDoc.id,
      });
      if (searchDoc.citation !== null) {
        setCitations([
          {
            id: searchDoc.citation.id || "",
            match: searchDoc.citation.text,
            exactMatch: false,
            page: searchDoc.citation.page,
          },
        ]);
        setPageNumber(searchDoc.citation.page);
      }
    }
  }, [searchDoc?.id]);

  useEffect(() => {
    setActiveModalRef(activeModalRef);

    return () => {
      setActiveModalRef(null);
    };
  }, [activeModalRef.current]);
  const clearData = async (saveSuccess: boolean) => {
    setSearchDoc(null);
    if (!saveSuccess) {
      setCitations([]);
      setPageNumber(1);
    }
  };

  const addCitation = async () => {
    setSaveLoading(true);
    if (citations.length) {
      const citationText = citations[0];
      const newCitation = {
        id: "",
        text: citationText?.match ?? "",
        page: pageNumber,
        doc_id: searchDoc?.id ?? "",
        doc_name: searchDoc?.name ?? "",
        formatted_text: citationText?.match ?? "",
        created_at: new Date().toISOString().slice(0, -1),
        user: {
          id: authInfo.user?.userId ?? "unknown",
          email: authInfo.user?.email ?? "unknown",
          first_name: authInfo.user?.firstName ?? "unknown",
          last_name: authInfo.user?.lastName ?? "unknown",
        },
        start_index: null,
        match_index: citations[0].match_index || null,
      };
      const response = await props.saveClick(newCitation);
      if (response !== null) {
        newCitation.id = response.citation_ids[0];
        if (props.successCallback) {
          props.successCallback(newCitation, response.departments);
        }
        clearData(true);
        props.setOpen(false);
        toast.success(`Citation added successfully`);
      } else {
        toast.error(`Failed to add citation`);
      }
    }
    setSaveLoading(false);
  };

  useEffect(() => {
    if (props.hideAtlasWidget) {
      toggleAtlasWidget(props.open);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onOpenChange={(dOpen) => {
        if (!dOpen) {
          clearData(false);
          if (props.onCancel) {
            props.onCancel();
          }
        }
        props.setOpen(dOpen);
      }}
    >
      <DialogContent
        className="flex flex-col max-w-[90%] h-[90%]"
        ref={activeModalRef as React.RefObject<HTMLDivElement>}
      >
        <DialogHeader>
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <DialogTitle>{props.title}</DialogTitle>
              <DialogDescription>{props.headerChildren}</DialogDescription>
            </div>
            <Button
              variant="default"
              className="w-[120px] mr-12"
              onClick={() => addCitation()}
            >
              Save
              {saveLoading && (
                <ReloadIcon className="w-4 h-4 ml-2 animate-spin" />
              )}
            </Button>
          </div>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <SearchResourceBar
              docTypeIds={props.allowedDocTypeIds}
              onItemSelect={setSearchDoc}
              suggestions={props.relevantDocs.map((doc) => ({
                id: doc.doc_id,
                name: doc.name,
                type: "document",
                match_type: "name",
                doc_type_name: doc.doc_type_name,
                citation: doc.citation,
                regulatory_doc_id: null,
                count: null,
              }))}
              placeholder="Search Documents..."
              autoSelect={searchDoc === null}
              disabled={props.allowedDocId !== undefined}
              docNameOnly={true}
              hideAtlasWidget={false}
            />
          </div>
          {docToView?.docId && (
            <DocViewerCitation
              docId={docToView?.docId}
              hideAtlasWidget={false}
              className="h-[calc(100vh-300px)]"
              parentModalRef={activeModalRef}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
